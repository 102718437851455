import { ServiceResult } from '../models';

export const apiCall = async <T extends ServiceResult>(responsePromise: Promise<T>, onSuccess: (reponse: T) => Promise<void>, onError: (response?: T) => Promise<void>) => {
    let response: T = null as any;

    try {
        response = await responsePromise;
    } catch { }

    if (response && !response.hasError) {
        await onSuccess(response);
    } else {
        await onError(response);
    }
};

export const apiCalls = async <T extends ServiceResult>(responsePromises: Promise<T[]>, onSuccess: (reponses: T[]) => Promise<void>, onError: () => Promise<void>) => {
    let responses: T[] = [];

    try {
        responses = await responsePromises;
    } catch { }

    if (responses && responses.every(x => x && !x.hasError)) {
        await onSuccess(responses);
    } else {
        await onError();
    }
};
