import { Reducer } from 'redux';
import { HeaderTabType } from '../enums';

export interface State {
    activeTabType: HeaderTabType;
}

interface SetActiveTabTypeAction {
    type: 'SET_ACTIVE_TAB_TYPE';
    tabType: HeaderTabType;
}

type KnownAction = SetActiveTabTypeAction;

export const actionCreators = {
    setActiveTabType: (tabType: HeaderTabType): SetActiveTabTypeAction => ({ type: 'SET_ACTIVE_TAB_TYPE', tabType })
};

export const reducer: Reducer<State, KnownAction> = (state, action) => {
    if (state === undefined) {
        return {
            activeTabType: HeaderTabType.Products
        };
    }

    switch (action.type) {
        case 'SET_ACTIVE_TAB_TYPE':
            return { activeTabType: action.tabType };
        default:
            return state;
    }
};
