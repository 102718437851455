import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';

const trackEvent = (event: IEventTelemetry) => {
    const windowAsAny = window as any;
    const applicationInsights: ApplicationInsights | null = windowAsAny.isProduction ? windowAsAny.appInsights : null;

    try {
        applicationInsights?.trackEvent(event);
    } catch (e) { }
};

export const track = (name: string, properties: { [key: string]: any }) => trackEvent({ name: name, properties });
