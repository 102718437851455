import React, { ReactNode } from 'react';

interface JumbotronProps {
    children?: ReactNode;
    className?: string;
}

const Jumbotron = ({ children, className }: JumbotronProps) => (
    <div className={`jumbotron ${className}`}>
        {children}
    </div>
);

export default Jumbotron;
