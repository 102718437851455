import { MULTIPLE_IMAGE_NAMES } from '../../constants';
import noImage from '../../../assets/images/products/legacy/retro_noimage.jpg';

export const useImages = () => {
    const getCardImagePath = (product: string, isLegacy = false) => {
        if (product) {
            try {
                if (isLegacy) {
                    const imageName = product.match(/^[0-9A-Z]+/)![0];

                    return require(`../../../assets/images/products/legacy/${imageName}.jpg`).default;
                } else {
                    const imageName = MULTIPLE_IMAGE_NAMES.find(multipleImageName => multipleImageName.includes(product));

                    return require(`../../../assets/images/products/${imageName || product}.png`).default;
                }
            } catch (e) {
                return noImage;
            }
        }
    };

    return {
        getCardImagePath
    };
};
