import { useEffect } from 'react';
import { Api, apiCall } from '../../services';

export const useEnvironment = () => {
    useEffect(() => {
        apiCall(
            Api.isProduction(),
            async x => {
                const windowAsAny = window as any;
                windowAsAny.isProduction = x.data;
            },
            async () => { }
        );
    }, []);
};
