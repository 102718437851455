import { ApiBase } from '.';
import { FilteredData, InitialData, ProductFilter, RetrofitFilter, RetrofitFilterValues, ServiceDataResult } from '../models';

export class Api extends ApiBase {
    static filterProducts = (filterModel: ProductFilter) => Api.post('api/proselector/filtereddata', filterModel) as Promise<ServiceDataResult<FilteredData>>;

    static loadInitialData = () => Api.get('api/proselector/initialdata') as Promise<ServiceDataResult<InitialData>>;

    static filterRetrofit = (filterModel: RetrofitFilter) => Api.post('api/retrofit/filtereddata', filterModel) as Promise<ServiceDataResult<FilteredData>>;

    static loadFilterValues = () => Api.get('api/retrofit/filtervalues') as Promise<ServiceDataResult<RetrofitFilterValues>>;

    static isProduction = () => Api.get('api/environment/production') as Promise<ServiceDataResult<boolean>>;
}
