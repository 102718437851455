import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, ApplicationState } from '../../store';
import { apiCall, Api } from '../../services';
import { TRANSLATIONS } from '../../constants';
import { SnackbarType } from '../../components/common/Snackbar';
import { useTranslate } from '../common';
import { Product, ProductFilter } from '../../models';
import { HeaderTabType } from '../../enums';

export const useProductFilter = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const filteredData = useSelector((state: ApplicationState) => state.selector.filteredProductData);
    const hiddenIds = useSelector((state: ApplicationState) => state.selector.hiddenIds);
    const favoriteProducts = useSelector((state: ApplicationState) => state.selector.favoriteProducts);
    const favoriteFunctions = useSelector((state: ApplicationState) => state.selector.favoriteFunctions);

    useEffect(() => {
        dispatch(actionCreators.toggleLoading());

        dispatch(actionCreators.setActiveTabType(HeaderTabType.Products));

        if (filteredData.products.length === 0) {
            getProductFilter();
        }

        dispatch(actionCreators.toggleLoading());
    }, []);

    const getProductFilter = async () => {
        await apiCall(
            Api.loadInitialData(),
            async x => {
                dispatch(actionCreators.setInitialData(x.data));
            },
            async () => {
                dispatch(actionCreators.addSnackbar({ text: translate(TRANSLATIONS.error.availableValues), type: SnackbarType.Error }));
            }
        );
    };

    const getFilteredData = async (productFilter: ProductFilter) => {
        dispatch(actionCreators.toggleLoading());

        await apiCall(
            Api.filterProducts(productFilter),
            async x => {
                dispatch(actionCreators.setFilteredProductData(x.data));
            },
            async () => {
                dispatch(actionCreators.addSnackbar({ text: translate(TRANSLATIONS.error.availableValues), type: SnackbarType.Error }));
            }
        );

        dispatch(actionCreators.toggleLoading());
    };

    const updateBestPrice = (products: Product[]) => {
        const bestPrice = Math.min(...products.filter(product => product.price !== 0).map(product => product.price));

        products.forEach(product => product.isBestPrice = product.price === bestPrice);

        return products;
    };

    const getProductsToDisplay = (): Product[] => {
        const filteredProducts = filteredData.products.filter(x => !hiddenIds.includes(x.productFullName));
        const productsToDisplay = favoriteProducts.concat(filteredProducts.filter(x => !favoriteProducts.map(y => y.productFullName).includes(x.productFullName)));

        return updateBestPrice(productsToDisplay);
    };

    const getAvailableFunctions = () => filteredData && filteredData.functions && filteredData.functions.filter(f => f.isAutoChecked).concat(favoriteFunctions);


    return {
        getFilteredData,
        getProductsToDisplay,
        getAvailableFunctions
    };
};
