import { Translations } from '../models';

const getTranslations = <T extends Translations>(translations: T): T => translations;

export const TRANSLATIONS = getTranslations({
    'error': {
        'availableValues': [
            'Cannot get available values',
            'Cannot get available values'
        ],
        'environment': [
            'An error occured during environment request',
            'An error occured during environment request'
        ]
    },
    'navmenu':{
        'title':[
            'Easy Protection Device Selector',
            'Einfache Auswahl Ihres Schutzgerätes'
        ],
        'retrofitTitle':[
            'Retrofit',
            'Retrofit'
        ],
        'searchFor':[
            'Search for ...',
            'Suche nach ...'
        ],
        'search':[
            'Search',
            'Suche'
        ],
        'language':[
            'Language',
            'Sprache'
        ],
        'selectLanguage':[
            'Please select a language.',
            'Bitte wählen Sie eine Sprache.'
        ],
        'english':[
            'English',
            'Englisch'
        ],
        'german':[
            'German',
            'Deutsch'
        ],
        'siemensGlobal':[
            'Siemens Global Website',
            'Siemens Globale Webseite'
        ],
        'reportProblem':[
            'Report a problem',
            'Problem melden'
        ]
    },
    'productSelection':{
        'selectionCriteria':[
            'Selection Criteria',
            'Auswahlkriterien'
        ],
        'application':[
            'Application',
            'Anwendung'
        ],
        'functionality':[
            'Functionality',
            'Funktionalität'
        ],
        'noAvailableFunction':[
            'No available function',
            'Keine verfügbare Funktion'
        ],
        'filterActive':[
            'Filter active',
            'Filter aktiv'
        ],
        'description':[
            'SIPROTEC and Reyrolle protection devices for digital substations',
            'SIPROTEC und Reyrolle Schutzgeräte für Digital Substations'
        ],
        'selectApplication':[
            'Please select ...',
            'Bitte wählen ...'
        ],
        'search':[
            'Search ...',
            'Suche ...'
        ]
    },
    'retrofitSelection':{
        'comment':[
            'Comment',
            'Kommentar'
        ],
        'filterTitle':[
            'Get the latest technology',
            'Erhalten Sie die neueste Technologie'
        ],
        'manufacturer':[
            'Select Manufacturing',
            'Hersteller wählen'
        ],
        'productShortCode':[
            'Select your Product Code',
            'Produktreihe wählen'
        ],
        'pleaseSelect':[
            'Please select ...',
            'Bitte wählen ...'
        ],
        'recommendation':[
            'Recommendation',
            'Empfehlung'
        ],
        'search':[
            'Search ...',
            'Suche ...'
        ],
        'legacyDataSheet':[
            'Download Center',
            'Download Center'
        ],
        'yourSelection':[
            'Your Selection',
            'Ihre Selektion'
        ],
        'dialog.info.retrofitDisclaimer.title':[
            'Notice',
            'Notice'
        ],
        'dialog.info.retrofitDisclaimer.text':[
            'The information on this website regarding compatibility§of our current product lines with products in the field is intended§as a first indication as to which of our products may be a suitable§retrofit recommendation for the product you intend to replace by§focusing on main specification criteria. Information regarding§Siemens or third party products is based on publicly available§information which may be subject to change. The information on§this website is not intended as a comparison of the complete§feature set of a selected product with the indicated Siemens§product or its general quality. We recommend contacting our§respective local sales support to confirm whether the Siemens§products indicated on this website fully fit to your requirements.',
            'The information on this website regarding compatibility§of our current product lines with products in the field is intended§as a first indication as to which of our products may be a suitable§retrofit recommendation for the product you intend to replace by§focusing on main specification criteria. Information regarding§Siemens or third party products is based on publicly available§information which may be subject to change. The information on§this website is not intended as a comparison of the complete§feature set of a selected product with the indicated Siemens§product or its general quality. We recommend contacting our§respective local sales support to confirm whether the Siemens§products indicated on this website fully fit to your requirements.'
        ],
        'dialog.info.retrofitDisclaimer.button':[
            'Confirm',
            'Bestätigen'
        ]
    },
    'productResults':{
        'more':[
            'more',
            'mehr'
        ],
        'less':[
            'less',
            'weniger'
        ],
        'bestPrice':[
            'Best price',
            'Bester Preis'
        ],
        'listPrice':[
            'List price from',
            'Listenpreis ab'
        ],
        'dataSheet':[
            'Datasheet',
            'Datenblatt'
        ],
        'configureBuy': [
            'Configure & Buy',
            'Configure & Buy'
        ],
        'specifySelectionCriteria':[
            'Please specify selection criteria',
            'Bitte Auswahlkriterien angeben'
        ],
        'noProducts':[
            'No available products',
            'Keine verfügbaren Produkte'
        ],
        'priceOnRequest':[
            'On request',
            'Auf Anfrage'
        ]
    },
    'footer':{
        'openSiemensWebsite':[
            'Open Siemens Website',
            'Siemens Webseite öffnen'
        ],
        'contactUs':[
            'Get in touch',
            'Kontaktieren Sie uns'
        ],
        'contactUsLink':[
            'https://www.siemens.com/contact/en/corporate/general.php',
            'https://www.siemens.com/contact/de/corporate/general.php'
        ],
        'changeRegion':[
            'Change region',
            'Region wechseln'
        ],
        'followUs':[
            'Follow our global channels',
            'Folgen Sie uns auf'
        ],
        'siemens':[
            'siemens.com Global Website',
            'Siemens Deutschland'
        ],
        'siemensLink':[
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/de.html'
        ],
        'corporateInformation':[
            'Corporate Information',
            'Impressum'
        ],
        'corporateInformationLink':[
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/de/general/legal.html'
        ],
        'privacyPolicy':[
            'Privacy Policy',
            'Datenschutz'
        ],
        'privacyPolicyLink':[
            'https://new.siemens.com/global/en/general/legal.html#item1-1798373668',
            'https://new.siemens.com/global/de/general/datenschutz.html'
        ],
        'cookiePolicy':[
            'Cookie Policy',
            'Cookie Richtlinien'
        ],
        'cookiePolicyLink':[
            'https://new.siemens.com/global/en/general/legal.html#tabentry-2006008823',
            'https://new.siemens.com/global/de/general/cookie-richtlinien.html'
        ],
        'termsOfUse':[
            'Terms of use',
            'Nutzungsbedingungen'
        ],
        'termsOfUseLink':[
            'https://new.siemens.com/global/en/general/legal.html#tabentry-478438873-1571773254',
            'https://new.siemens.com/global/de/general/nutzungsbedingungen.html'
        ],
        'digitalId':[
            'Digital ID',
            'Digitales Zertifikat'
        ],
        'digitalIdLink':[
            'https://new.siemens.com/global/en/general/legal.html#tabentry-358122541-958035137',
            'https://new.siemens.com/global/de/general/digitales-zertifikat.html'
        ]
    },
    'functionTypes':{
        'standard':[
            'Standard',
            'Standard'
        ],
        'optional':[
            'Optional',
            'Optional'
        ]
    },
    'tooltips':{
        '0':[
            'in preparation',
            'In Vorbereitung'
        ],
        '1':[
            'via CFC',
            'Via CFC'
        ],
        '2':[
            '= IO number of a standard variant (increased configuration available using the SIPROTEC 5 system) / number of current and voltage inputs up to 40',
            '= E/ A-Anzahl einer Standardvariante (erhöhte Konfigurationsmöglichkeiten bei Verwendung des SIPROTEC 5 Systems) / Anzahl Strom- und Spannungseingänge bis 40'
        ]
    },
    'cssClassNames':{
        'jumbotron-custom-height':[
            'jumbotron-custom-heightEN',
            'jumbotron-custom-heightDE'
        ]
    },
    'applications':{
        '11': [
            'Distance protection',
            'Distanzschutz'
        ],
        '2': [
            'Line differential protection',
            'Leitungsdifferentialschutz'
        ],
        '3': [
            'Combined line differential and distance protection',
            'Kombinierter Leitungsdifferential- und Distanzschutz'
        ],
        '4': [
            'High impedance protection',
            'Hochimpedanzschutz'
        ],
        '5': [
            'Universal protection',
            'Universalschutz'
        ],
        '6': [
            'Overcurrent and feeder protection',
            'Überstromzeit- und Abzweigschutz'
        ],
        '7': [
            'Feeder Protection',
            'Verteilnetzschutz'
        ],
        '1': [
            'Generator and motor protection',
            'Generator- und Motorschutz'
        ],
        '8': [
            'Transformer protection',
            'Transformatorschutz'
        ],
        '9': [
            'Busbar protection',
            'Sammelschienenschutz'
        ],
        '10': [
            'Bay controller',
            'Feldleitgerät'
        ],
        '17': [
            'Merging Unit',
            'Merging Unit'
        ],
        '12': [
            'Breaker management',
            'Schaltermanagement'
        ],
        '13': [
            'Synchronizing',
            'Synchronisierung'
        ],
        '14': [
            'High Speed Busbar Transfer',
            'Schnellumschalteinrichtung'
        ],
        '15': [
            'Voltage and frequency protection',
            'Spannungs- und Frequenzschutz'
        ],
        '16': [
            'Fault Recorder',
            'Störschreiber'
        ]
    },
    'functions':{
        '196': [
            'Protection functions for 3-pole tripping',
            'Schutzfunktionen für 3-polige Auslösung'
        ],
        '171': [
            'Protection functions for 1-pole tripping',
            'Schutzfunktionen für 1-polige Auslösung'
        ],
        '70': [
            'Locked rotor',
            'Blockierter Läufer'
        ],
        '69': [
            'Distance protection',
            'Distanzschutz'
        ],
        '68': [
            'Impedance protection for transformers',
            'Impedanzschutz für Tranformatoren'
        ],
        '67': [
            'Overexcitation protection',
            'Übererregungsschutz'
        ],
        '66': [
            'Synchrocheck, synchronization function',
            'Synchrocheck, Synchronisierungsfunktion'
        ],
        '65': [
            'Synchrocheck, synchronization function with balancing commands (V7.82 and higher)',
            'Synchrocheck, Synchronisierungsfunktion mit Stellbefehlen (ab V7.82)'
        ],
        '64': [
            'Paralleling function 1.5 channel (significant feature: up to 8 sync. locations)',
            'Synchronisierungsfunktion 1,5-kanalig (maßgebliche Eigenschaft: bis zu 8 Synchronisierstellen)'
        ],
        '63': [
            'Paralleling function 2 channel (significant feature: up to 8 sync. locations)',
            'Synchronisierungsfunktion 2-kanalig (maßgebliche Eigenschaft: bis zu 8 Synchronisierstellen)'
        ],
        '62': [
            'Undervoltage protection',
            'Unterspannungsschutz'
        ],
        '71': [
            '100% stator ground-fault protection with 3rd harmonic',
            '100% Ständererdschlussschutz mit 3. Harmonischer'
        ],
        '61': [
            'Undervoltage-controlled reactive power protection',
            'Blindleistungsrichtungs-Unterspannungsschutz (QU-Schutz)'
        ],
        '59': [
            'Directional power supervision',
            'Gerichtete Leistungsüberwachung'
        ],
        '58': [
            'Power-plant disconnection protection',
            'Kraftwerksentkupplungs-Schutz'
        ],
        '57': [
            'Undercurrent',
            'Unterstrom'
        ],
        '56': [
            'Temperature supervision',
            'Temperaturüberwachung'
        ],
        '55': [
            'Underexcitation protection',
            'Untererregungsschutz'
        ],
        '54': [
            'Unbalanced-load protection',
            'Schieflastschutz'
        ],
        '53': [
            'Negative-sequence system overcurrent protection',
            'Gegensystem-Überstromzeitschutz'
        ],
        '52': [
            'Phase-sequence-voltage supervision',
            'Drehfeldüberwachung'
        ],
        '51': [
            'Overvoltage protection, negative-sequence system',
            'Überspannungsschutz, Gegensystem'
        ],
        '60': [
            'Overvoltage protection, negative-sequence/positive-sequence system',
            'Überspannungsschutz, Gegensystem/Mitsystem'
        ],
        '72': [
            'Starting-time supervision for motors',
            'Anlaufzeitüberwachung für Motoren'
        ],
        '73': [
            'Thermal overload protection',
            'Thermischer Überlastschutz'
        ],
        '74': [
            'Thermal overload protection, user-defined characteristic',
            'Thermischer Überlastschutz, benutzerdefinierte Kennlinie'
        ],
        '95': [
            'Thermal overload protection for RLC filter elements of a capacitor bank',
            'Überlastschutz für RLC-Filterkreiselemente einer Kondensatorbank'
        ],
        '94': [
            'Field-winding overload protection',
            'Läuferwicklungs-Überlastschutz'
        ],
        '93': [
            'Stator overload protection with cold-gas consideration',
            'Läuferüberlastschutz mit Kaltgas-Berücksichtigung'
        ],
        '92': [
            'Stator overload protection with cold-gas consideration',
            'Ständerüberlastschutz mit Kaltgas-Berücksichtigung'
        ],
        '91': [
            'Hotspot calculation',
            'Heißpunktberechnung'
        ],
        '90': [
            'Rotor overload protection',
            'Läuferüberlastschutz'
        ],
        '89': [
            'Stator overload protection',
            'Ständerüberlastschutz'
        ],
        '88': [
            'Definite time-overcurrent protection',
            'Unabhängiger Überstromzeitschutz'
        ],
        '87': [
            'Instantaneous tripping at switch onto fault',
            'Schnellauslösung bei Zuschalten auf Fehler'
        ],
        '86': [
            'Arc protection',
            'Lichtbogenschutz'
        ],
        '85': [
            'Sensitive ground-current protection',
            'Empfindlicher Erdstromschutz'
        ],
        '84': [
            'Sensitive ground-fault detection for systems with resonant or isolated neutral via 3I0 pulse pattern detection',
            'Empfindliche Erdschlusserfassung für gelöschte und isolierte Netze über Pulsortung'
        ],
        '83': [
            'Sensitive ground-current detection for systems with resonant or isolated neutral systems, incl. a) 3I0>, b) admittance Y0>, c) 3I0-harm> (from V7.8)',
            'Empfindlicher Erdschlusserfassung für gelöschte und isolierte Netze inkl. a) 3I0>, b) Admittanz Y0>, c) 3I0-harm> (ab V7.8)'
        ],
        '82': [
            'Intermittent ground-fault protection',
            'Intermittierender Erdfehlerschutz'
        ],
        '81': [
            'Overcurrent protection for RLC filter elements of a capacitor bank',
            'Überstromzeitschutz für RLC-Filterkreiselemente einer Kondensatorbank'
        ],
        '80': [
            'Shaft-current protection',
            'Wellenstromschutz'
        ],
        '79': [
            'Inadvertent energization protection (generator)',
            'Zuschaltschutz (Generator)'
        ],
        '78': [
            'Direct-current/direct-voltage protection',
            'Gleichstrom-/Gleichspannungsschutz'
        ],
        '77': [
            'Startup overcurrent protection',
            'Anfahrüberstromzeitschutz'
        ],
        '76': [
            'End-fault protection',
            'Endfehlerschutz'
        ],
        '75': [
            'Circuit-breaker failure protection',
            'Leistungsschalter-Versagerschutz'
        ],
        '50': [
            'Circuit-breaker restrike protection',
            'Leistungsschalter-Rückzündeüberwachung'
        ],
        '96': [
            'Inverse time-overcurrent protection',
            'Abhängiger Überstromzeitschutz'
        ],
        '49': [
            'Load-jam protection',
            'Lastsprungschutz'
        ],
        '47': [
            'Cold load pickup',
            'Dynamische Ansprechwertumschaltung'
        ],
        '21': [
            'Overcurrent protection, voltage dependent',
            'Spannungsabhängiger Überstromzeitschutz'
        ],
        '20': [
            'Power factor',
            'Leistungsfaktor'
        ],
        '19': [
            'Overvoltage protection',
            'Überspannungsschutz'
        ],
        '18': [
            'Peak overvoltage protection, 3-phase, for capacitors',
            'Spitzenüberspannungsschutz, 3-phasig, für Kondensatoren'
        ],
        '17': [
            'Turn-to-turn fault protection',
            'Windungsschlussschutz'
        ],
        '16': [
            'Overvoltage protection, zero-sequence system',
            'Überspannungsschutz, Nullsystem'
        ],
        '15': [
            'Rate-of-voltage-change protection',
            'Spannungsänderungsschutz'
        ],
        '14': [
            'Current-unbalance protection for capacitor banks',
            'Stromunsymmetrieschutz für Kondensatorbänke'
        ],
        '13': [
            'Measuring-voltage failure detection',
            'Messspannungsausfall-Erkennung'
        ],
        '22': [
            'Sensitive ground-fault protection (machine); 90% V0 and 100% V20 Hz stator ground fault protection',
            'Erdschlussschutz (Maschine); 90% U0 und 100% U20 Hz Ständererdschlussschutz'
        ],
        '12': [
            'Rotor ground-fault protection (IRgnd>, fn)',
            'Läufererdschlussschutz (IRE>, fn)'
        ],
        '10': [
            'Rotor ground-fault protection (Rgnd<, fn)',
            'Läufererdschlussschutz (RE<, fn)'
        ],
        '9': [
            'Rotor ground-fault protection (1 - 3 Hz)',
            'Läufererdschlussschutz (1 - 3 Hz)'
        ],
        '8': [
            'Restart inhibit',
            'Wiedereinschaltsperre'
        ],
        '7': [
            'Directional overcurrent protection, phases',
            'Gerichteter Überstromzeitschutz, Phasen'
        ],
        '6': [
            'Directional ground-fault protection in grounded systems',
            'Gerichteter Erdkurzschlussschutz für geerdete Netze'
        ],
        '5': [
            'Dir. sensitive ground-fault detection for systems with resonant or isolated neutral',
            'Ger. Empfindliche Erdfehlererfassung für gelöschte und isolierte Netz'
        ],
        '4': [
            'Sensitive ground-fault detection for systems with resonant or isolated neutral with admittanz method',
            'Empfindliche Erdschlusserfassung für gelöschte und isolierte Netze nach dem Admittanzverfahren'
        ],
        '3': [
            'Sensitive ground-fault detection for systems with resonant or isolated neutral with phasor measurement of 3rd or 5th harmonic',
            'Empfindliche Erdschlusserfassung für gelöschte und isolierte Netze über Zeigermessung mit 3. oder 5. Harmonischer'
        ],
        '2': [
            'Transient ground-fault function, for transient and permanent ground faults in resonant-grounded or isolated networks',
            'Erdschluss-Wischer Funktion, für transiente und statische Erdschlüsse in gelöschten und isolierten Netze'
        ],
        '11': [
            'Directional intermittent ground-fault protection',
            'Gerichteter intermittierender Erdfehlerschutz'
        ],
        '23': [
            'Power-swing blocking',
            'Pendelsperre'
        ],
        '24': [
            'Trip-circuit supervision',
            'Auslösekreisüberwachung'
        ],
        '25': [
            'Closed-circuit supervision',
            'EIN-Kreisüberwachung'
        ],
        '46': [
            'Out-of-step protection',
            'Außertrittfallschutz'
        ],
        '45': [
            'Automatic reclosing',
            'Automatische Wiedereinschaltung'
        ],
        '44': [
            'Frequency protection',
            'Frequenzschutz'
        ],
        '43': [
            'Abnormal frequency protection',
            'Schutz gegen abnormale Frequenzen'
        ],
        '42': [
            'Under Frequency Load Shedding',
            'Automatische Frequenzentlastung'
        ],
        '41': [
            'Rate-of-frequency-change protection',
            'Frequenzänderungsschutz'
        ],
        '40': [
            'Vector-jump protection',
            'Vektorsprungschutz'
        ],
        '39': [
            'Load restoration',
            'Lastzuschaltung'
        ],
        '38': [
            'Teleprotection',
            'Signalverfahren'
        ],
        '37': [
            'Lockout',
            'Einschaltsperre'
        ],
        '36': [
            'Busbar differential protection',
            'Sammelschienendifferentialschutz'
        ],
        '35': [
            'Bus coupler differential protection',
            'Kuppelfeld-Differentialschutz'
        ],
        '34': [
            'Generator differential protection',
            'Generatordifferentialschutz'
        ],
        '33': [
            'Transformer differential protection',
            'Transformatordifferentialschutz'
        ],
        '32': [
            'Differential protection, phase-angle regulating transformer (single core)',
            'Differentialschutz, Phasenschieber-Transformatoren (Single Core)'
        ],
        '31': [
            'Differential protection, Phase-angle regulating transformer (two core)',
            'Differentialschutz, Phasenschieber-Transformatoren (Two Core)'
        ],
        '30': [
            'Differential protection, Special transformers',
            'Differentialschutz, Spezialtransformatoren'
        ],
        '29': [
            'Differential protection (Node protection for auto transformer)',
            'Differentialschutz (Knotenpunktschutz für Spartransformator)'
        ],
        '28': [
            'Cross stabilization',
            'Querstabilisierung'
        ],
        '27': [
            'Motor differential protection',
            'Motordifferentialschutz'
        ],
        '26': [
            'Differential protection, lines',
            'Differentialschutz, Leitungen'
        ],
        '48': [
            'Differential protection, capacitor bank',
            'Differentialschutz, Kondensatorbank'
        ],
        '97': [
            'Voltage differential protection, capacitor bank',
            'Spannungsdifferentialschutz, Kondensatorbänke'
        ],
        '98': [
            'Stub-differential protection',
            'Stub-Fehler-Differentialschutz'
        ],
        '99': [
            'Restricted ground-fault protection',
            'Erdfehler-Differentialschutz'
        ],
        '169': [
            'Broken-wire detection for differential protection',
            'Drahtbrucherkennung für Differentialschutz'
        ],
        '168': [
            'Voltage controller for two-winding transformer',
            'Spannungsregler  für Zweiwicklungstransformator'
        ],
        '167': [
            'Voltage controller for three- winding transformer',
            'Spannungsregler für Dreiwicklungstransformator'
        ],
        '166': [
            'Voltage controller for grid coupling transformer',
            'Spannungsregler Netzkupplungstransformator'
        ],
        '165': [
            'Automatic voltage control for parallel transformer',
            'Spannungsregler für parallele Transformatoren'
        ],
        '164': [
            'Fault locator',
            'Fehlerorter'
        ],
        '163': [
            'Synchrophasor measurement',
            'Synchrozeiger-Messung'
        ],
        '162': [
            'Measured values',
            'Messwerte'
        ],
        '161': [
            'Power Quality Basic functions (from V8.40)',
            'Power-Quality-Basis Funktionen (ab V8.40)'
        ],
        '160': [
            'Switching-statistic counters',
            'Schaltstatistik'
        ],
        '159': [
            'Circuit-breaker wear monitoring',
            'Leistungsschalter Monitoring'
        ],
        '158': [
            'Logic editor',
            'Logikeditor'
        ],
        '157': [
            'Inrush-current detection',
            'Einschaltstromerkennung'
        ],
        '156': [
            'External trip initiation',
            'Externe Einkopplung'
        ],
        '155': [
            'Control',
            'Steuerung'
        ],
        '154': [
            'Point-on-wave switching',
            'Phasengenaues Schalten'
        ],
        '153': [
            'High Speed busbar transfer function',
            'Schnellumschaltfunktion'
        ],
        '152': [
            'Fault recording of analog and binary signals',
            'Störschreibung analoger und binärer Signale'
        ],
        '151': [
            'Extended fault recording',
            'Erweiterte Störschreibung'
        ],
        '150': [
            'Fast-scan recorder',
            'Fast-Scan-Schreiber'
        ],
        '149': [
            'Slow-scan recorder',
            'Slow-Scan-Schreiber'
        ],
        '170': [
            'Continuous recorder',
            'Kontinuierlicher Schreiber'
        ],
        '172': [
            'Trend recorder',
            'Trendschreiber'
        ],
        '195': [
            'Power Quality recordings (functionalities)',
            'Power Quality-Aufzeichnungen (Funktionen)'
        ],
        '173': [
            'Sequence-of-events recorder',
            'Ereignisfolgenschreiber'
        ],
        '194': [
            'Extended trigger functions',
            'Erweiterte Trigger-Funktionen'
        ],
        '193': [
            'Frequency tracking groups (from V7.8)',
            'Frequenzgruppen Nachführung (ab V7.8)'
        ],
        '192': [
            'Cyber Security: Role Based Access Control (from V7.8)',
            'Cyber Security rollenbasierte Zugriffskontrolle (ab V7.8)'
        ],
        '191': [
            'Monitoring and supervision',
            'Überwachung'
        ],
        '190': [
            'Protection interface, serial',
            'Wirkschnittstelle, seriell'
        ],
        '189': [
            'No. Setting groups',
            'Anzahl Parametersätze'
        ],
        '188': [
            'Changeover of setting group',
            'Parametersatzumschaltung'
        ],
        '187': [
            'Circuit breaker test',
            'Leistungsschalterprüfung'
        ],
        '186': [
            'Hardware quantity structure expandable',
            'Hardware-Mengengerüst erweiterbar'
        ],
        '185': [
            'Binary Inputs (max)',
            'Binäre Eingänge (max)'
        ],
        '184': [
            'Binary Outputs (max) incl. Life contact',
            'Binäre Ausgänge (max) inkl. Live Kontakt'
        ],
        '183': [
            'Internal RTD Inputs (max)',
            'interne RTD Eingänge (max)'
        ],
        '182': [
            'Transducer inputs (0-20 mA) (max)',
            'Analoge Eingänge (0-20 mA) (max)'
        ],
        '181': [
            'Transducer inputs (0-10 V) (max)',
            'Analoge Eingänge (0-10 V) (max)'
        ],
        '180': [
            'Analog measured value outputs (0-20 mA) (max)',
            'Analoge Ausgänge (0-20 mA) (max)'
        ],
        '179': [
            'Current Inputs (max)',
            'Stromeingänge (max)'
        ],
        '178': [
            'Voltage Inputs (max)',
            'Spannungseingänge (max)'
        ],
        '177': [
            'Low Power CT Inputs',
            'Kleinsignalstromeingänge'
        ],
        '176': [
            'Low Power VT Inputs',
            'Kleinsignalspannungseingänge'
        ],
        '175': [
            'Case (x19\'\')',
            'Gehäuse (x19 \'\')'
        ],
        '174': [
            'Size (xE)',
            'Baugröße (x E)'
        ],
        '148': [
            'No Display',
            'kein Display'
        ],
        '147': [
            'Small Display (lines)',
            'kleines Display (Zeilen)'
        ],
        '146': [
            'Large, graphical Display (Pixel)',
            'großes, grafisches Display (Pixel)'
        ],
        '145': [
            'Push Buttons',
            'Funktionstasten'
        ],
        '119': [
            'Key Switch',
            'Schlüsselschalter'
        ],
        '118': [
            'LEDs (max)',
            'LEDs (max)'
        ],
        '117': [
            'Conformal Coating',
            'Conformal Coating (lackierte Baugruppen)'
        ],
        '116': [
            'Pluggable terminal blocks',
            'steckbare Klemmenblöcke'
        ],
        '115': [
            'PSU Variants',
            'Stromversorgungsvarianten'
        ],
        '114': [
            'Redundant power supply',
            'Redundante Stromversorgung'
        ],
        '113': [
            'Front User Interface',
            'Front-Bedienschnittstelle'
        ],
        '112': [
            'IEC 60870-5-101',
            'IEC 60870-5-101'
        ],
        '111': [
            'IEC 60870-5-103',
            'IEC 60870-5-103'
        ],
        '120': [
            'IEC 60870-5-104',
            'IEC 60870-5-104'
        ],
        '110': [
            'Profibus FMS, Slave',
            'Profibus FMS, Slave'
        ],
        '108': [
            'Profibus DP, Slave',
            'Profibus DP, Slave'
        ],
        '107': [
            'Modbus TCP',
            'Modbus TCP'
        ],
        '106': [
            'Modbus RTU Slave',
            'Modbus RTU Slave'
        ],
        '105': [
            'PROFINET I/O',
            'PROFINET I/O'
        ],
        '104': [
            'PROFINET I/O S2 redundancy',
            'PROFINET I/O S2 Redundanz'
        ],
        '103': [
            'DNP3 serial',
            'DNP3 seriell'
        ],
        '102': [
            'DNP 3 TCP',
            'DNP3 TCP'
        ],
        '101': [
            'IEC 61850-8-1',
            'IEC 61850-8-1'
        ],
        '100': [
            'IEC 61850-9-2 Server (Merging Unit)',
            'IEC 61850-9-2 Server (Merging Unit)'
        ],
        '109': [
            'IEC 61850-9-2 Client (PB Client)',
            'IEC 61850-9-2 Client (PB Client)'
        ],
        '121': [
            'DIGSI',
            'DIGSI'
        ],
        '122': [
            'RTD-unit connection',
            'Thermobox Anschluss'
        ],
        '123': [
            '20 mA unit connection',
            '20 mA Unit Anschluss'
        ],
        '144': [
            'Protection Interface (PI)',
            'Wirkschnittstelle'
        ],
        '143': [
            'Synchrophasor (IEEE C37.118)',
            'Synchrophasor (IEEE C37.118)'
        ],
        '142': [
            'Redundancy Protocols',
            'Redundanzprotokolle'
        ],
        '141': [
            'RSTP (Ethernet ring redundancy)',
            'RSTP (Ethernet Ringredundanz)'
        ],
        '140': [
            'PRP V1 (Parallel Redundancy Protocol)',
            'PRP V1 (Parallel Redundancy Protocol)'
        ],
        '139': [
            'HSR (High available Seamless Ring configuration)',
            'HSR (High available Seamless Ring configuration)'
        ],
        '138': [
            'VLAN (Virtual Local Area Network)',
            'VLAN (Virtual Local Area Network)'
        ],
        '137': [
            'Further Ethernet protocols on Ethernet modules',
            'Weitere Ethernetdienst lauffähig auf Ethernetmodulen'
        ],
        '136': [
            'Time Synchronisation',
            'Zeitsynchronisierung'
        ],
        '135': [
            'IEEE 1588v2/PTP Accuracy 1µs',
            'IEEE 1588v2/PTP Genauigkeit 1µs'
        ],
        '134': [
            'IEEE 1588v2/PTP Accuracy 1ms',
            'IEEE 1588v2/PTP Genauigkeit 1ms'
        ],
        '133': [
            'Timesync. via integrated GPS module',
            'Zeitsynch über integriertes GPS Modul'
        ],
        '132': [
            'Patch Management and ProductCERT',
            'Patch-Management und ProductCERT'
        ],
        '131': [
            'Password protection',
            'Passwortschutz'
        ],
        '130': [
            'Central user management and RBAC',
            'Zentrales Nutzermanagement und RBAC'
        ],
        '129': [
            'Secure engineering and web communication',
            'Sichere Engineering- und Webkommunikation'
        ],
        '128': [
            'Network segregation (physical / virtual - VLAN)',
            'Netzwerktrennung (physikalisch / virtuell - VLAN)'
        ],
        '127': [
            'Certificate management',
            'Zertifikatsmanagement'
        ],
        '126': [
            'Digitally signed firmware',
            'Digital signierte Firmware'
        ],
        '125': [
            'Crypto chip (hardware security module)',
            'Crypto Chip (Hardware Security Modul)'
        ],
        '124': [
            'Monitoring of security relevant events (Syslog)',
            'Überwachung von sicherheitsrelevanten Ereignissen (Syslog)'
        ],
        '1': [
            'SNMP monitoring',
            'SNMP-Überwachung'
        ]
    },
    'abbreviations':{
        '196': [
            '3-pole',
            '3-polig'
        ],
        '171': [
            '1-pole',
            '1-polig'
        ],
        '70': [
            'I> + V<',
            'I> + n<'
        ],
        '69': [
            'Z<, V< /I>/∠(V,I)',
            'Z<, U< /I>/∠(U,I)'
        ],
        '68': [
            'Z<',
            'Z<'
        ],
        '67': [
            'V/f',
            'U/f'
        ],
        '66': [
            'Sync',
            'Sync'
        ],
        '65': [
            'Sync',
            'Sync'
        ],
        '64': [
            'Sync',
            'Sync'
        ],
        '63': [
            'Sync',
            'Sync'
        ],
        '62': [
            'V<',
            'U<'
        ],
        '71': [
            'V03.H<, V03.H>; ΔV03.H',
            'U03.H<, U03.H>; ΔU03.H'
        ],
        '61': [
            'Q>/V<',
            'Q>/U<'
        ],
        '59': [
            'P<>, Q<>',
            'P<>, Q<>'
        ],
        '58': [
            'dP/dt<',
            'dP/dt<'
        ],
        '57': [
            'I<, P<',
            'I<, P<'
        ],
        '56': [
            'Θ>',
            'Θ>'
        ],
        '55': [
            '1/xd',
            '1/xd'
        ],
        '54': [
            'I2>',
            'I2>'
        ],
        '53': [
            'I2>, I2/I1>',
            'I2>, I2/I1>'
        ],
        '52': [
            'LA, LB, LC',
            'L1, L2, L3'
        ],
        '51': [
            'V2>',
            'U2>'
        ],
        '60': [
            'V2/V1>',
            'U2/U1>'
        ],
        '72': [
            'I2start',
            'I2start'
        ],
        '73': [
            'θ, I2t',
            'θ, I2t'
        ],
        '74': [
            'θ, I2t',
            'θ, I2t'
        ],
        '95': [
            'θ, I2t',
            'θ, I2t'
        ],
        '94': [
            'I2t',
            'I2t'
        ],
        '93': [
            'θ>, I2t',
            'θ>, I2t'
        ],
        '92': [
            'θ>, I2t',
            'θ>, I2t'
        ],
        '91': [
            'θh>, I2t',
            'θh, I2t'
        ],
        '90': [
            'I2t',
            'I2t'
        ],
        '89': [
            'I2t',
            'θ, I2t'
        ],
        '88': [
            'I>',
            'I>'
        ],
        '87': [
            '',
            ''
        ],
        '86': [
            '',
            ''
        ],
        '85': [
            'INs>',
            'INs>'
        ],
        '84': [
            'IN-pulse',
            'IN-Puls'
        ],
        '83': [
            'INs>',
            'INs>'
        ],
        '82': [
            'Iie>',
            'IIE>'
        ],
        '81': [
            'I>',
            'I>'
        ],
        '80': [
            'INs>',
            'INs>'
        ],
        '79': [
            'I>, V<reset',
            'I>, U< Rückfall'
        ],
        '78': [
            'IDC<>, VDC <>',
            'IDC<>, UDC <>'
        ],
        '77': [
            'I-Start >',
            'I-Anf>'
        ],
        '76': [
            '',
            'EFP'
        ],
        '75': [
            'CBFP',
            'LSVS'
        ],
        '50': [
            'CBRS',
            'LSRZ'
        ],
        '96': [
            'IP, INp',
            'IP, INp'
        ],
        '49': [
            'I>L',
            'I>L'
        ],
        '47': [
            '',
            ''
        ],
        '21': [
            't=f(I)+V<',
            't=f(I)+U<'
        ],
        '20': [
            'cosj',
            'cosj'
        ],
        '19': [
            'V>',
            'U>'
        ],
        '18': [
            'V> cap.',
            'U> Kond.'
        ],
        '17': [
            'V0>',
            'U0>'
        ],
        '16': [
            'V0>',
            'U0>'
        ],
        '15': [
            'dV/dt',
            'dU/dt'
        ],
        '14': [
            'Iunbal>',
            'Iunbal>'
        ],
        '13': [
            '',
            ''
        ],
        '22': [
            '',
            ''
        ],
        '12': [
            'IRgnd>',
            'IRG>'
        ],
        '10': [
            'RRgnd<',
            'RG<'
        ],
        '9': [
            'RRgnd<',
            'RG<'
        ],
        '8': [
            'I2t',
            'I2t'
        ],
        '7': [
            'I>, ∠ (V,I)',
            'I>, ∠ (U,I)'
        ],
        '6': [
            'IN>, ∠ (V,I)',
            'IN>, ∠ (U,I)'
        ],
        '5': [
            'INs>, ∠(V,I)',
            'INs>, ∠ (U,I)'
        ],
        '4': [
            'G0>, B0>',
            'G0>, B0>'
        ],
        '3': [
            'V0>, ∠ (Uharm.,Iharm.)',
            'U0>, ∠ (Uharm.,Iharm.)'
        ],
        '2': [
            'W0p,tr>',
            'W0p,tr>'
        ],
        '11': [
            'Iie dir>',
            'IIEdir>'
        ],
        '23': [
            'ΔZ/Δt',
            'ΔZ/Δt'
        ],
        '24': [
            'TCS',
            'AKU'
        ],
        '25': [
            'CCS',
            ''
        ],
        '46': [
            'ΔZ/Δt',
            'ΔZ/Δt'
        ],
        '45': [
            'AR',
            'AWE'
        ],
        '44': [
            'f<, f>',
            'f<, f>'
        ],
        '43': [
            'fBand',
            'fBand'
        ],
        '42': [
            'f<(UFLS)',
            'f<(AFE)'
        ],
        '41': [
            'df/dt',
            'df/dt'
        ],
        '40': [
            '∆φU>',
            '∆φU>'
        ],
        '39': [
            'LR',
            'LZ'
        ],
        '38': [
            '',
            ''
        ],
        '37': [
            '',
            ''
        ],
        '36': [
            'ΔI',
            'ΔI'
        ],
        '35': [
            '',
            ''
        ],
        '34': [
            'ΔI',
            'ΔI'
        ],
        '33': [
            'ΔI',
            'ΔI'
        ],
        '32': [
            'ΔI',
            'ΔI'
        ],
        '31': [
            'ΔI',
            'ΔI'
        ],
        '30': [
            'ΔI',
            'ΔI'
        ],
        '29': [
            'ΔI Node',
            'ΔI Knoten'
        ],
        '28': [
            '',
            ''
        ],
        '27': [
            'ΔI',
            'ΔI'
        ],
        '26': [
            'ΔI',
            'ΔI'
        ],
        '48': [
            'ΔI',
            'ΔI'
        ],
        '97': [
            '',
            'ΔV'
        ],
        '98': [
            'ΔI',
            'ΔI'
        ],
        '99': [
            'ΔIN',
            'ΔIN'
        ],
        '169': [
            '',
            ''
        ],
        '168': [
            '',
            ''
        ],
        '167': [
            '',
            ''
        ],
        '166': [
            '',
            ''
        ],
        '165': [
            '',
            ''
        ],
        '164': [
            'FL',
            'FO'
        ],
        '163': [
            'PMU',
            'PMU'
        ],
        '162': [
            '',
            ''
        ],
        '161': [
            'PQ-Basic',
            ''
        ],
        '160': [
            '',
            ''
        ],
        '159': [
            'ΣIx, I²t, 2P',
            'ΣIx, I²t, 2P'
        ],
        '158': [
            '',
            ''
        ],
        '157': [
            '',
            ''
        ],
        '156': [
            '',
            ''
        ],
        '155': [
            '',
            ''
        ],
        '154': [
            'PoW',
            'PoW'
        ],
        '153': [
            '',
            ''
        ],
        '152': [
            '',
            ''
        ],
        '151': [
            '',
            ''
        ],
        '150': [
            'FSR',
            'FSR'
        ],
        '149': [
            'SSR',
            'SSR'
        ],
        '170': [
            'CR',
            'CR'
        ],
        '172': [
            'TR',
            'TR'
        ],
        '195': [
            'PQR',
            'PQR'
        ],
        '194': [
            'ExTrFct',
            'ExTrFkt'
        ],
        '193': [
            '',
            ''
        ],
        '192': [
            '',
            ''
        ],
        '191': [
            '',
            ''
        ],
        '190': [
            '',
            ''
        ],
        '188': [
            '',
            ''
        ],
        '187': [
            '',
            ''
        ],
        '186': [
            'I/O',
            'E/A'
        ],
        '119': [
            '',
            ''
        ],
        '113': [
            '',
            ''
        ],
        '112': [
            '',
            ''
        ],
        '111': [
            '',
            ''
        ],
        '120': [
            '',
            ''
        ],
        '110': [
            '',
            ''
        ],
        '108': [
            '',
            ''
        ],
        '107': [
            '',
            ''
        ],
        '106': [
            '',
            ''
        ],
        '105': [
            '',
            ''
        ],
        '104': [
            '',
            ''
        ],
        '103': [
            '',
            ''
        ],
        '102': [
            '',
            ''
        ],
        '101': [
            '',
            ''
        ],
        '100': [
            '',
            ''
        ],
        '109': [
            '',
            ''
        ],
        '121': [
            '',
            ''
        ],
        '122': [
            '',
            ''
        ],
        '123': [
            '',
            ''
        ],
        '144': [
            '',
            ''
        ],
        '143': [
            '',
            ''
        ],
        '142': [
            '',
            ''
        ],
        '141': [
            '',
            ''
        ],
        '140': [
            '',
            ''
        ],
        '139': [
            '',
            ''
        ],
        '138': [
            '',
            ''
        ],
        '137': [
            '',
            ''
        ],
        '136': [
            '',
            ''
        ],
        '135': [
            '',
            ''
        ],
        '134': [
            '',
            ''
        ],
        '133': [
            '',
            ''
        ],
        '132': [
            '',
            ''
        ],
        '131': [
            '',
            ''
        ],
        '130': [
            '',
            ''
        ],
        '129': [
            '',
            ''
        ],
        '128': [
            '',
            ''
        ],
        '127': [
            '',
            ''
        ],
        '126': [
            '',
            ''
        ],
        '125': [
            '',
            ''
        ],
        '124': [
            '',
            ''
        ],
        '1': [
            '',
            ''
        ]
    },
    'headers':{
        '1': [
            'Functions',
            'Funktionen'
        ],
        '4': [
            'Further Functions',
            'Weitere Funktionen'
        ],
        '3': [
            'Hardware Feature',
            'Hardware Eigenschaften'
        ],
        '2': [
            'Communication',
            'Kommunikation'
        ],
        '5': [
            'Cyber Security',
            'Cyber Security'
        ]
    },
    'marketingSiteLinks':{
        '57SA82':[
            'www.siemens.com/7SA82',
            'www.siemens.de/7SA82'
        ],
        '57SA86':[
            'www.siemens.com/7SA86',
            'www.siemens.de/7SA86'
        ],
        '57SA87':[
            'www.siemens.com/7SA87',
            'www.siemens.de/7SA87'
        ],
        '47SA522':[
            'www.siemens.com/7SA522',
            'www.siemens.de/7SA522'
        ],
        '47SA61':[
            'www.siemens.com/7SA61',
            'www.siemens.de/7SA61'
        ],
        '47SA63':[
            'www.siemens.com/7SA63',
            'www.siemens.de/7SA63'
        ],
        '47SA64':[
            'www.siemens.com/7SA64',
            'www.siemens.de/7SA64'
        ],
        '57SD82':[
            'www.siemens.com/7SD82',
            'www.siemens.de/7SD82'
        ],
        '57SD86':[
            'www.siemens.com/7SD86',
            'www.siemens.de/7SD86'
        ],
        '57SD87':[
            'www.siemens.com/7SD87',
            'www.siemens.de/7SD87'
        ],
        'C7SD80':[
            'www.siemens.com/7SD80',
            'www.siemens.de/7SD80'
        ],
        '47SD610':[
            'www.siemens.com/7SD610',
            'www.siemens.de/7SD610'
        ],
        '47SD5':[
            'www.siemens.com/7SD5',
            'www.siemens.de/7SD5'
        ],
        'R7PG2111':[
            'www.siemens.com/7PG2111',
            'www.siemens.de/7PG2111'
        ],
        'R7SR18':[
            'www.siemens.com/7SR18',
            'www.siemens.de/7SR18'
        ],
        '57SL82':[
            'www.siemens.com/7SL82',
            'www.siemens.de/7SL82'
        ],
        '57SL86':[
            'www.siemens.com/7SL86',
            'www.siemens.de/7SL86'
        ],
        '57SL87':[
            'www.siemens.com/7SL87',
            'www.siemens.de/7SL87'
        ],
        'R7SR23':[
            'www.siemens.com/7SR23',
            'www.siemens.de/7SR23'
        ],
        'R7PG23':[
            'www.siemens.com/7PG23',
            'www.siemens.de/7PG23'
        ],
        '5C7SX800':[
            'www.siemens.com/7SX800',
            'www.siemens.de/7SX800'
        ],
        '57SJ81':[
            'www.siemens.com/7SJ81',
            'www.siemens.de/7SJ81'
        ],
        '57SJ82':[
            'www.siemens.com/7SJ82',
            'www.siemens.de/7SJ82'
        ],
        '57SJ85':[
            'www.siemens.com/7SJ85',
            'www.siemens.de/7SJ85'
        ],
        '57SJ86':[
            'www.siemens.com/7SJ86',
            'www.siemens.de/7SJ86'
        ],
        'C7SJ80':[
            'www.siemens.com/7SJ80',
            'www.siemens.de/7SJ80'
        ],
        'C7SJ81':[
            'www.siemens.com/7SJ81',
            'www.siemens.de/7SJ81'
        ],
        '47SJ61':[
            'www.siemens.com/7SJ61',
            'www.siemens.de/7SJ61'
        ],
        '47SJ62':[
            'www.siemens.com/7SJ62',
            'www.siemens.de/7SJ62'
        ],
        '47SJ64':[
            'www.siemens.com/7SJ64',
            'www.siemens.de/7SJ64'
        ],
        '47SJ66':[
            'www.siemens.com/7SJ66',
            'www.siemens.de/7SJ66'
        ],
        'R7SR10':[
            'www.siemens.com/7SR10',
            'www.siemens.de/7SR10'
        ],
        'R7SR11':[
            'www.siemens.com/7SR11',
            'www.siemens.de/7SR11'
        ],
        'R7SR12':[
            'www.siemens.com/7SR12',
            'www.siemens.de/7SR12'
        ],
        'R7SR45':[
            'www.siemens.com/7SR45',
            'www.siemens.de/7SR45'
        ],
        'R7SR191':[
            'www.siemens.com/7SR191',
            'www.siemens.de/7SR191'
        ],
        'R7SR210':[
            'www.siemens.com/7SR210',
            'www.siemens.de/7SR210'
        ],
        'R7SR220':[
            'www.siemens.com/7SR220',
            'www.siemens.de/7SR220'
        ],
        'R57SR51':[
            'www.siemens.com/7SR51',
            'www.siemens.de/7SR51'
        ],
        'C7SC80':[
            'www.siemens.com/7SC80',
            'www.siemens.de/7SC80'
        ],
        'R7SR224':[
            'www.siemens.com/7SR224',
            'www.siemens.de/7SR224'
        ],
        '57SK82':[
            'www.siemens.com/7SK82',
            'www.siemens.de/7SK82'
        ],
        '57SK85':[
            'www.siemens.com/7SK85',
            'www.siemens.de/7SK85'
        ],
        '57UM85':[
            'www.siemens.com/7UM85',
            'www.siemens.de/7UM85'
        ],
        'C7SK80':[
            'www.siemens.com/7SK80',
            'www.siemens.de/7SK80'
        ],
        'C7SK81':[
            'www.siemens.com/7SK81',
            'www.siemens.de/7SK81'
        ],
        '47UM62':[
            'www.siemens.com/7UM62',
            'www.siemens.de/7UM62'
        ],
        'R7SR105':[
            'www.siemens.com/7SR105',
            'www.siemens.de/7SR105'
        ],
        'R7SR17':[
            'www.siemens.com/7SR17',
            'www.siemens.de/7SR17'
        ],
        'R57SR57':[
            'www.siemens.com/7SR57',
            'www.siemens.de/7SR57'
        ],
        '57UT82':[
            'www.siemens.com/7UT82',
            'www.siemens.de/7UT82'
        ],
        '57UT85':[
            'www.siemens.com/7UT85',
            'www.siemens.de/7UT85'
        ],
        '57UT86':[
            'www.siemens.com/7UT86',
            'www.siemens.de/7UT86'
        ],
        '57UT87':[
            'www.siemens.com/7UT87',
            'www.siemens.de/7UT87'
        ],
        '47UT612':[
            'www.siemens.com/7UT612',
            'www.siemens.de/7UT612'
        ],
        '47UT613':[
            'www.siemens.com/7UT613',
            'www.siemens.de/7UT613'
        ],
        '47UT63':[
            'www.siemens.com/7UT63',
            'www.siemens.de/7UT63'
        ],
        'R7SR242':[
            'www.siemens.com/7SR242',
            'www.siemens.de/7SR242'
        ],
        'R57SR54':[
            'www.siemens.com/7SR54',
            'www.siemens.de/7SR54'
        ],
        '57SS85':[
            'www.siemens.com/7SS85',
            'www.siemens.de/7SS85'
        ],
        '47SS52':[
            'www.siemens.com/7SS52',
            'www.siemens.de/7SS52'
        ],
        '56MD85':[
            'www.siemens.com/6MD85',
            'www.siemens.de/6MD85'
        ],
        '56MD86':[
            'www.siemens.com/6MD86',
            'www.siemens.de/6MD86'
        ],
        '46MD66':[
            'www.siemens.com/6MD66',
            'www.siemens.de/6MD66'
        ],
        '56MU805':[
            'www.siemens.com/6MU805',
            'www.siemens.de/6MU805'
        ],
        '56MU85':[
            'www.siemens.com/6MU85',
            'www.siemens.de/6MU85'
        ],
        '57VK87':[
            'www.siemens.com/7VK87',
            'www.siemens.de/7VK87'
        ],
        '47VK61':[
            'www.siemens.com/7VK61',
            'www.siemens.de/7VK61'
        ],
        '57VE85':[
            'www.siemens.com/7VE85',
            'www.siemens.de/7VE85'
        ],
        'R7SR157':[
            'www.siemens.com/7SR157',
            'www.siemens.de/7SR157'
        ],
        '47VU683':[
            'www.siemens.com/7VU683',
            'www.siemens.de/7VU683'
        ],
        'C7RW80':[
            'www.siemens.com/7RW80',
            'www.siemens.de/7RW80'
        ],
        'R7SR158':[
            'www.siemens.com/7SR158',
            'www.siemens.de/7SR158'
        ],
        '57KE85':[
            'www.siemens.com/7KE85',
            'www.siemens.de/7KE85'
        ]
    },
    'dataSheetLinks':{
        '57SA82':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1689',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1688'
        ],
        '57SA86':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1690',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1691'
        ],
        '57SA87':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1692',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1693'
        ],
        '47SA522':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '47SA61':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '47SA63':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '47SA64':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '57SD82':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1695',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1694'
        ],
        '57SD86':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1697',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1696'
        ],
        '57SD87':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1699',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1698'
        ],
        'C7SD80':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_412',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_411'
        ],
        '47SD610':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '47SD5':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        'R7PG2111':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1460',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1460'
        ],
        'R7SR18':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1777',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1777'
        ],
        '57SL82':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1702',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1703'
        ],
        '57SL86':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1705',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1704'
        ],
        '57SL87':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1707',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1706'
        ],
        'R7SR23':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_661',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_661'
        ],
        'R7PG23':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1173',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1173'
        ],
        '5C7SX800':[
            'https://www.siemens.com/download?DLA07_3135',
            'https://www.siemens.com/download?DLA07_3134'
        ],
        '57SJ81':[
            'https://www.siemens.com/download?DLA07_2530',
            'https://www.siemens.com/download?DLA07_2531'
        ],
        '57SJ82':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1723',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1722'
        ],
        '57SJ85':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1725',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1724'
        ],
        '57SJ86':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1701',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1700'
        ],
        'C7SJ80':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_273',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_274'
        ],
        'C7SJ81':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_412',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_411'
        ],
        '47SJ61':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '47SJ62':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '47SJ64':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '47SJ66':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_4390',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_4389'
        ],
        'R7SR10':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1655',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1655'
        ],
        'R7SR11':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_887',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_887'
        ],
        'R7SR12':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_887',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_887'
        ],
        'R7SR45':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1215',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1232'
        ],
        'R7SR191':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_824',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_824'
        ],
        'R7SR210':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1459',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1459'
        ],
        'R7SR220':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1459',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1459'
        ],
        'R57SR51':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1459',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1459'
        ],
        'C7SC80':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_744',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_745'
        ],
        'R7SR224':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_779',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_779'
        ],
        '57SK82':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1479',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1478'
        ],
        '57SK85':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1727',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1726'
        ],
        '57UM85':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1733',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1732'
        ],
        'C7SK80':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_273',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_274'
        ],
        'C7SK81':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_412',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_411'
        ],
        '47UM62':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        'R7SR105':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1631',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1631'
        ],
        'R7SR17':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_888',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_888'
        ],
        'R57SR57':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_2772',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_2772'
        ],
        '57UT82':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1715',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1714'
        ],
        '57UT85':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1717',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1716'
        ],
        '57UT86':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1718',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1719'
        ],
        '57UT87':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1721',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1720'
        ],
        '47UT612':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '47UT613':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '47UT63':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        'R7SR242':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1392',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1392'
        ],
        'R57SR54':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1459',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_1459'
        ],
        '57SS85':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1730',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1731'
        ],
        '47SS52':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '56MD85':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1735',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1734'
        ],
        '56MD86':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1712',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1711'
        ],
        '46MD66':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '56MU805':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_2358',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_2359'
        ],
        '56MU85':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_2358',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_2359'
        ],
        '57VK87':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1710',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1709'
        ],
        '47VK61':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        '57VE85':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_2375',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_2376'
        ],
        'R7SR157':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_843',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_843'
        ],
        '47VU683':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2013',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA06_2012'
        ],
        'C7RW80':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_412',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA11_411'
        ],
        'R7SR158':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_787',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA04_787'
        ],
        '57KE85':[
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1729',
            'https://www.downloads.siemens.com/download-center/Download.aspx?pos=download&fct=getasset&mandator=ic_sg&id1=DLA07_1728'
        ]
    },
    'configureLinks':{
        '57SA82':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57SA86':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57SA87':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '47SA522':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7SA522&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7SA522&tab=Product'
        ],
        '47SA61':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7SA61&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7SA61&tab=Product'
        ],
        '47SA63':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7SA63&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7SA63&tab=Product'
        ],
        '47SA64':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7SA64&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7SA64&tab=Product'
        ],
        '57SD82':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57SD86':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57SD87':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        'C7SD80':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SD80',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SD80'
        ],
        '47SD610':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Product/?mlfb=7SD610.-.....-....',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Product?mlfb=7SD610.-.....-....'
        ],
        '47SD5':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7SD5&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7SD5&tab=Product'
        ],
        'R7PG2111':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7PG2111*&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7PG2111*&tab=Product'
        ],
        'R7SR18':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Product?mlfb=7SR181.-.....-..A0',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Product/?mlfb=7SR181.-.....-..A0'
        ],
        '57SL82':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57SL86':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57SL87':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        'R7SR23':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Product/?mlfb=7SR230.-.....-....',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Product?mlfb=7SR230.-.....-....'
        ],
        'R7PG23':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7PG23*&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7PG23*&tab=Product'
        ],
        '5C7SX800':[
            'https://mall.industry.siemens.com/goos/WelcomePage.aspx?regionUrl=/b1&language=en',
            'https://mall.industry.siemens.com/goos/WelcomePage.aspx?language=de&regionUrl=/b1'
        ],
        '57SJ81':[
            'https://mall.industry.siemens.com/mall/en/b1/Catalog/Products/10361182?tree=CatalogTree',
            'https://mall.industry.siemens.com/mall/de/b1/Catalog/Products/10361182?tree=CatalogTree'
        ],
        '57SJ82':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57SJ85':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57SJ86':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        'C7SJ80':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ80',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ80'
        ],
        'C7SJ81':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ81',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ81'
        ],
        '47SJ61':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ61',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ61'
        ],
        '47SJ62':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ62',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ62'
        ],
        '47SJ64':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ64',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ64'
        ],
        '47SJ66':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ66',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SJ66'
        ],
        'R7SR10':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR10',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR10'
        ],
        'R7SR11':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR11',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR11'
        ],
        'R7SR12':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR12',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR12'
        ],
        'R7SR45':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR45',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR45'
        ],
        'R7SR191':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Product?mlfb=7SR191.-.....-..A0',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Product/?mlfb=7SR191.-.....-..A0'
        ],
        'R7SR210':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR21',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR21'
        ],
        'R7SR220':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR22',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR22'
        ],
        'R57SR51':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Product?mlfb=7SR51',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Product/?mlfb=7SR51'
        ],
        'C7SC80':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Product/?mlfb=7SC80..-.....-....',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Product?mlfb=7SC80..-.....-....'
        ],
        'R7SR224':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7SR224*&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7SR224*&tab=Product'
        ],
        '57SK82':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57SK85':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57UM85':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        'C7SK80':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SK80',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SK80'
        ],
        'C7SK81':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SK81',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SK81'
        ],
        '47UM62':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7UM62',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7UM62'
        ],
        'R7SR105':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR10',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR10'
        ],
        'R7SR17':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR17',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR17'
        ],
        'R57SR57':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7SR57&tab=Product',
            'https://mall.industry.siemens.com/mall/de/de/Catalog/Search?searchTerm=7SR57&tab=Product'
        ],
        '57UT82':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57UT85':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57UT86':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57UT87':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '47UT612':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7UT612',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7UT612'
        ],
        '47UT613':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7UT613',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7UT613'
        ],
        '47UT63':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7UT63&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7UT63&tab=Product'
        ],
        'R7SR242':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR24',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7SR24'
        ],
        'R57SR54':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7SR54&tab=Product',
            'https://mall.industry.siemens.com/mall/de/de/Catalog/Search?searchTerm=7SR54&tab=Product'
        ],
        '57SS85':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '47SS52':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7SS52*&tab=Product',
            'https://mall.industry.siemens.com/mall/de/ww/Catalog/Search/?searchTerm=7SS52*&tab=Product'
        ],
        '56MD85':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '56MD86':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '46MD66':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=6MD66*&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=6MD66*&tab=Product'
        ],
        '56MU805':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '56MU85':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '57VK87':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        '47VK61':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Search?searchTerm=7VK61*&tab=Product',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Search?searchTerm=7VK61*&tab=Product'
        ],
        '57VE85':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ],
        'R7SR157':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Product?mlfb=7SR157.-.....-..A0',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Product/?mlfb=7SR157.-.....-..A0'
        ],
        '47VU683':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Product/?mlfb=7VU683.-.....-....',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Product?mlfb=7VU683.-.....-....'
        ],
        'C7RW80':[
            'https://mall.industry.siemens.com/mall/en/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7RW80',
            'https://mall.industry.siemens.com/mall/de/de/entryPoints/StartConfigurator?ConfigId=33&nodeid=10021668&kmat=7RW80'
        ],
        'R7SR158':[
            'https://mall.industry.siemens.com/mall/en/WW/Catalog/Product?mlfb=7SR158.-.....-..A0',
            'https://mall.industry.siemens.com/mall/de/WW/Catalog/Product/?mlfb=7SR158.-.....-..A0'
        ],
        '57KE85':[
            'https://w3.siemens.com/smartgrid/global/en/products-systems-solutions/Protection/Pages/protection-relay-configurator.aspx',
            'https://w3.siemens.com/smartgrid/global/de/produkte-systeme-loesungen/schutztechnik/Pages/protection-relay-konfigurator.aspx'
        ]
    },
    'legacyProductLinks':{
        '7SJ41':[
            '',
            ''
        ],
        '7SJ45':[
            '',
            ''
        ],
        '7SJ46':[
            '',
            ''
        ],
        '7SJ50':[
            '',
            ''
        ],
        '7SJ500':[
            '',
            ''
        ],
        '7SJ511':[
            '',
            ''
        ],
        '7SJ512':[
            '',
            ''
        ],
        '7SJ531':[
            '',
            ''
        ],
        '7SJ550':[
            '',
            ''
        ],
        '7SJ551':[
            '',
            ''
        ],
        '7SJ600':[
            '',
            ''
        ],
        '7SJ601':[
            '',
            ''
        ],
        '7SJ602':[
            '',
            ''
        ],
        '7SJ61':[
            '',
            ''
        ],
        '7SJ62':[
            '',
            ''
        ],
        '7SJ63':[
            '',
            ''
        ],
        '7SJ64':[
            '',
            ''
        ],
        '7SN600':[
            '',
            ''
        ],
        '7SK52':[
            '',
            ''
        ],
        '7UT512':[
            '',
            ''
        ],
        '7UT513':[
            '',
            ''
        ],
        '7UT612':[
            '',
            ''
        ],
        '7UT613':[
            '',
            ''
        ],
        '7UT63':[
            '',
            ''
        ],
        '7UM511':[
            '',
            ''
        ],
        '7UM512':[
            '',
            ''
        ],
        '7UM515':[
            '',
            ''
        ],
        '7UM516':[
            '',
            ''
        ],
        '7UM61':[
            '',
            ''
        ],
        '7UM62':[
            '',
            ''
        ],
        '7SD502':[
            '',
            ''
        ],
        '7SD503':[
            '',
            ''
        ],
        '7SD511':[
            '',
            ''
        ],
        '7SD512':[
            '',
            ''
        ],
        '7SD600':[
            '',
            ''
        ],
        '7SD610':[
            '',
            ''
        ],
        '7SD52':[
            '',
            ''
        ],
        '7SD53':[
            '',
            ''
        ],
        '7SD84':[
            '',
            ''
        ],
        '7SA510':[
            '',
            ''
        ],
        '7SA511':[
            '',
            ''
        ],
        '7SA513':[
            '',
            ''
        ],
        '7SA517':[
            '',
            ''
        ],
        '7SA518':[
            '',
            ''
        ],
        '7SA519':[
            '',
            ''
        ],
        '7SA52':[
            '',
            ''
        ],
        '7SA61':[
            '',
            ''
        ],
        '7SA63':[
            '',
            ''
        ],
        '7SA526':[
            '',
            ''
        ],
        '7SA84':[
            '',
            ''
        ],
        '7RE2':[
            '',
            ''
        ],
        '7RW60':[
            '',
            ''
        ],
        '7VH60':[
            '',
            ''
        ],
        '7UW50':[
            '',
            ''
        ],
        '7SV512':[
            '',
            ''
        ],
        '7SV600':[
            '',
            ''
        ],
        '7TS15':[
            '',
            ''
        ],
        '7VE51':[
            '',
            ''
        ],
        '7VE61':[
            '',
            ''
        ],
        '7VE63':[
            '',
            ''
        ],
        '7VK51':[
            '',
            ''
        ],
        '7VK61':[
            '',
            ''
        ],
        '7SS50,1':[
            '',
            ''
        ],
        '7SS520':[
            '',
            ''
        ],
        '7SS523':[
            '',
            ''
        ],
        '7SS521(V1.x)':[
            '',
            ''
        ],
        '7SS60_(&Comp._7T,4AM)':[
            '',
            ''
        ],
        '7XR60':[
            '',
            ''
        ],
        '7XR61_(3PP13)':[
            '',
            ''
        ],
        '3PP1326':[
            '',
            ''
        ],
        '3PP1336':[
            '',
            ''
        ],
        '7XT31':[
            '',
            ''
        ],
        '7XT32':[
            '',
            ''
        ],
        '7XT70':[
            '',
            ''
        ],
        '7KE6000':[
            '',
            ''
        ],
        '7KE6100':[
            '',
            ''
        ],
        '7PA10':[
            '',
            ''
        ],
        '7PA11':[
            '',
            ''
        ],
        '7PA2_-_short_MLFB_______(8-digits_)':[
            '',
            ''
        ],
        '7PA3_-_short_MLFB_______(8-digits_)':[
            '',
            ''
        ],
        '7PA20':[
            '',
            ''
        ],
        '7PA21':[
            '',
            ''
        ],
        '7XV72':[
            '',
            ''
        ],
        '6MD61':[
            '',
            ''
        ],
        '6MD63':[
            '',
            ''
        ],
        '6MD66':[
            '',
            ''
        ],
        '7SG111':[
            '',
            ''
        ],
        '7SG112':[
            '',
            ''
        ],
        '7SG114':[
            '',
            ''
        ],
        '7SG115':[
            '',
            ''
        ],
        '7SG116':[
            '',
            ''
        ],
        '7SG117':[
            '',
            ''
        ],
        '7SG118':[
            '',
            ''
        ],
        '7SG12':[
            '',
            ''
        ],
        '7SG130':[
            '',
            ''
        ],
        '7SG14':[
            '',
            ''
        ],
        '7SG16':[
            '',
            ''
        ],
        '7SG17':[
            '',
            ''
        ],
        '7SG18,_VCE:2512H100_(4)':[
            '',
            ''
        ],
        '7PG26':[
            '',
            ''
        ],
        'P120':[
            '',
            ''
        ],
        'P121':[
            '',
            ''
        ],
        'P122':[
            '',
            ''
        ],
        'P123':[
            '',
            ''
        ],
        'P125':[
            '',
            ''
        ],
        'P126':[
            '',
            ''
        ],
        'P127':[
            '',
            ''
        ],
        'P141':[
            '',
            ''
        ],
        'P142':[
            '',
            ''
        ],
        'P143':[
            '',
            ''
        ],
        'P144':[
            '',
            ''
        ],
        'P145':[
            '',
            ''
        ],
        'P441':[
            '',
            ''
        ],
        'P433':[
            '',
            ''
        ],
        'P435':[
            '',
            ''
        ],
        'P439':[
            '',
            ''
        ],
        'P442':[
            '',
            ''
        ],
        'P443':[
            '',
            ''
        ],
        'P444':[
            '',
            ''
        ],
        'P445':[
            '',
            ''
        ],
        'P446':[
            '',
            ''
        ],
        'P541':[
            '',
            ''
        ],
        'P542':[
            '',
            ''
        ],
        'P543':[
            '',
            ''
        ],
        'P544':[
            '',
            ''
        ],
        'P545':[
            '',
            ''
        ],
        'P546':[
            '',
            ''
        ],
        'P3U30':[
            '',
            ''
        ],
        'P3M30':[
            '',
            ''
        ],
        'P3G30':[
            '',
            ''
        ],
        'SEL-300G':[
            '',
            ''
        ],
        'SEL-311C-1':[
            '',
            ''
        ],
        'SEL-311C-2/-3':[
            '',
            ''
        ],
        'SEL-311L-0/-1':[
            '',
            ''
        ],
        'SEL-311L-6/-7':[
            '',
            ''
        ],
        'SEL-321':[
            '',
            ''
        ],
        'SEL-321-5':[
            '',
            ''
        ],
        'SEL-351':[
            '',
            ''
        ],
        'SEL-351A':[
            '',
            ''
        ],
        'SEL-351S':[
            '',
            ''
        ],
        'SEL-352':[
            '',
            ''
        ],
        'SEL-387':[
            '',
            ''
        ],
        'SEL-387A':[
            '',
            ''
        ],
        'SEL-387E':[
            '',
            ''
        ],
        'SEL-387L':[
            '',
            ''
        ],
        'SEL-400G':[
            '',
            ''
        ],
        'SEL-T400L':[
            '',
            ''
        ],
        'SEL-401':[
            '',
            ''
        ],
        'SEL-T401L':[
            '',
            ''
        ],
        'SEL-411L':[
            '',
            ''
        ],
        'SEL-421':[
            '',
            ''
        ],
        'SEL-451':[
            '',
            ''
        ],
        'SEL-487B':[
            '',
            ''
        ],
        'SEL-487E':[
            '',
            ''
        ],
        'SEL-487V':[
            '',
            ''
        ],
        'SEL-501':[
            '',
            ''
        ],
        'SEL-547':[
            '',
            ''
        ],
        'SEL-551':[
            '',
            ''
        ],
        'SEL-551C':[
            '',
            ''
        ],
        'SEL-587':[
            '',
            ''
        ],
        'SEL-587Z':[
            '',
            ''
        ],
        'SEL-700BT':[
            '',
            ''
        ],
        'SEL-700G':[
            '',
            ''
        ],
        'SEL-701':[
            '',
            ''
        ],
        'SEL-710':[
            '',
            ''
        ],
        'SEL-710-5':[
            '',
            ''
        ],
        'SEL-751':[
            '',
            ''
        ],
        'SEL-751A':[
            '',
            ''
        ],
        'SEL-787':[
            '',
            ''
        ],
        'SEL-787-2X':[
            '',
            ''
        ],
        'SEL-787-21':[
            '',
            ''
        ],
        'SEL-787-2E':[
            '',
            ''
        ],
        'SEL-787-3':[
            '',
            ''
        ],
        'SEL-787-4':[
            '',
            ''
        ],
        'SEL-849':[
            '',
            ''
        ]
    },
    'header': {
        'language': [
            'Language',
            'Sprache'
        ],
        'languages': {
            'en': [
                'English',
                'English'
            ],
            'de': [
                'Deutsch',
                'Deutsch'
            ]
        },
        'skipToMainContent': [
            'Skip to main content',
            'Skip to main content'
        ],
        'support': [
            'Support',
            'Unterstützung'
        ],
        'reportProblem': [
            'Report a problem',
            'Ein Problem melden'
        ]
    }
});
