import { TRANSLATIONS } from '../constants';
import { Language } from '../enums';
import { ProductFilter, RetrofitFilter } from '../models';

const getProductIdByName = (product: string, language: Language) => Number(Object.keys(TRANSLATIONS.applications).find(key => (TRANSLATIONS.applications as any)[key][language] === product));

export const setApplicationFilter = (product: string, language: Language): ProductFilter => ({ selectedApplicationId: getProductIdByName(product, language), selectedFunctionIds: [] });

export const setFunctionFilter = (filter: ProductFilter, functionIds: number[]): ProductFilter => ({ ...filter, selectedFunctionIds: functionIds });

export const setManufacturerFilter = (manufacturer: string): RetrofitFilter => ({ selectedManufacturer: manufacturer, selectedProductShortCode: '' });

export const setProductFilter = (filter: RetrofitFilter, product: string): RetrofitFilter => ({ ...filter, selectedProductShortCode: product });
