import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Layout from './ts/components/layout/Layout';
import ProductSelector from './ts/components/application/ProductSelector';
import RetrofitSelector from './ts/components/application/RetrofitSelector';

const routes = (
    <Routes>
        <Route path='/*' element={<Layout><Outlet /></Layout>}>
            <Route path='*' element={<RetrofitSelector />} />
        </Route>
    </Routes>
);

export default routes;
