import { configureHooks } from '../providers';
import { useEnvironment, useProductFilter, useRetrofitFilter, useImages } from './application';

export const hooks = configureHooks({
    images: { hook: useImages },
    environment: { hook: useEnvironment, global: true },
    productFilter: { hook: useProductFilter },
    retrofitFilter: { hook: useRetrofitFilter }
});

export const useHook = hooks.useHook;
