export const MULTIPLE_IMAGE_NAMES: string[] = [
    '47SD5_47VE6',
    'R7SR11_R7SR12',
    '47UM61_47UM62',
    '47SA522_47VK61',
    '47SA61_47SD610_47SJ61_47SJ62',
    '57SA82_57SD82_57SL82_57SJ82_57SK82',
    'C7SD80_C7SJ80_C7SJ81_C7SK80_C7SK81_C7RW80',
    '57SA86_57SA87_57SD86_57SD87_57SL86_57SL87_57SJ85_57SJ86_57SK85_57UM85_57UT85_57UT86_57UT87_56MD85_56MD86_57VK87_57VE85'
];
