import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../store';
import { apiCall, Api } from '../../services';
import { TRANSLATIONS } from '../../constants';
import { SnackbarType } from '../../components/common/Snackbar';
import { useTranslate } from '../common';
import { RetrofitFilter } from '../../models';
import { HeaderTabType } from '../../enums';

export const useRetrofitFilter = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();

    useEffect(() => {
        dispatch(actionCreators.toggleLoading());

        dispatch(actionCreators.setActiveTabType(HeaderTabType.Retrofit));
        getRetrofitFilter();

        dispatch(actionCreators.toggleLoading());
    }, []);

    const getRetrofitFilter = async () => {
        await apiCall(
            Api.loadFilterValues(),
            async x => {
                dispatch(actionCreators.setRetrofitFilterValues(x.data));
            },
            async () => {
                dispatch(actionCreators.addSnackbar({ text: translate(TRANSLATIONS.error.availableValues), type: SnackbarType.Error }));
            }
        );
    };

    const getFilteredData = async (retrofitFilter: RetrofitFilter) => {
        if (retrofitFilter.selectedManufacturer && retrofitFilter.selectedProductShortCode) {
            dispatch(actionCreators.toggleLoading());

            await apiCall(
                Api.filterRetrofit(retrofitFilter),
                async x => {
                    dispatch(actionCreators.setFilteredRetrofitData(x.data));
                },
                async () => {
                    dispatch(actionCreators.addSnackbar({ text: translate(TRANSLATIONS.error.availableValues), type: SnackbarType.Error }));
                }
            );

            dispatch(actionCreators.toggleLoading());
        }
    };

    return {
        getFilteredData
    };
};
