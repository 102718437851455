import { Attribute, FunctionModel, Product } from '../models';

const getFunctionsForProduct = (product: Product, functions: FunctionModel[]): FunctionModel[] => functions?.filter(a => a.productFunctionInfoPairs.find(x => `${Object.values(x)[0]}` === product.productFullName));

const distinctById = (array: FunctionModel[]): FunctionModel[] => Array.from(new Map(array?.map(item => [item.id, item])).values());

export const getDistinctProductAttributeRows = (productAttributes: Record<number, Attribute>[], products: Product[]) => productAttributes.map((record, i) => !products.every(product => Object.values(product.productAttributes[i])[1].value === Object.values(record)[1].value));

export const getDistinctFunctionAttributeRows = (products: Product[], functions: FunctionModel[]) => {
    const functionRow: boolean[] = [];
    const productsWithFunctions = products.map(x => ({ product: x, functions: getProductFunctions(x, products, functions) }));

    if (productsWithFunctions.length > 1) {
        functions && functions.forEach((_, index) => {
            const notEmptyProductFunctions = productsWithFunctions.filter(b => !b.functions[index]?.isEmpty);

            if (notEmptyProductFunctions.length > 1) {

                const productsWithFunctionInfos = notEmptyProductFunctions.map(a =>
                ({
                    functionInfo: a.functions[index]?.productFunctionInfoPairs.find(c => `${Object.values(c)[0]}` === a.product.productFullName)
                }));

                functionRow.push(!productsWithFunctionInfos.every(a => Object.values(a.functionInfo ?? {})[1]?.isOptional === Object.values(productsWithFunctionInfos[0].functionInfo ?? {})[1]?.isOptional));
            } else {
                functionRow.push(false);
            }
        });
    }

    return functionRow;
};

export const getProductFunctions = (product: Product, products: Product[], functions: FunctionModel[]) => {
    const currentProductFunctions = getFunctionsForProduct(product, functions) ?? [];
    const emptyFunction = { isEmpty: true } as FunctionModel;
    const newFunctions: FunctionModel[] = [];
    const distinctFunctions = distinctById(products.map(x => getFunctionsForProduct(x, functions)).reduce((a, b) => a?.concat(b)));

    distinctFunctions.forEach((func, index) => {
        if (!currentProductFunctions[index]) {
            newFunctions.push(currentProductFunctions.find(a => a.id === func.id) || emptyFunction);
        } else {
            if (currentProductFunctions[index].id !== func.id) {
                newFunctions.push(!currentProductFunctions.some(a => a.id === func.id) ? emptyFunction : currentProductFunctions.find(a => a.id === func.id) as FunctionModel);
            } else {
                newFunctions.push(currentProductFunctions[index]);
            }
        }
    });

    return newFunctions;
};
