import { Reducer } from 'redux';
import { FilteredData, FunctionModel, InitialData, Product, ProductFilter, RetrofitFilter, RetrofitFilterValues } from '../models';

export interface State {
    initialData: InitialData;
    productFilter: ProductFilter;
    retrofitFilterValues: RetrofitFilterValues;
    retroFilter: RetrofitFilter;
    filteredProductData: FilteredData;
    filteredRetrofitData: FilteredData;
    loading: boolean;
    moreAttributesOpen: boolean;
    moreFunctionsOpen: boolean;
    products: Product[];
    hiddenIds: string[];
    newIds: string[];
    searchName: string;
    favoriteProducts: Product[];
    favoriteFunctions: FunctionModel[];
}

interface ToggleLoadingAction {
    type: 'TOGGLE_LOADING';
}

interface AddHiddenIdAction {
    type: 'ADD_HIDDEN_ID';
    name: string;
}

interface ClearHiddenIdsAction {
    type: 'CLEAR_HIDDEN_IDS';
}

interface AddNewIdsAction {
    type: 'ADD_NEW_IDS';
    names: string[];
}

interface ClearNewIdsAction {
    type: 'CLEAR_NEW_IDS';
}

interface RemoveNewIdAction {
    type: 'REMOVE_NEW_ID';
    name: string;
}

interface SetInitialDataAction {
    type: 'SET_INITIAL_DATA';
    initialData: InitialData;
}

interface SetProductFilterAction {
    type: 'SET_PRODUCT_FILTER';
    filter: ProductFilter;
}

interface SetFilteredProductDataAction {
    type: 'SET_FILTERED_PRODUCT_DATA';
    filteredData: FilteredData;
}

interface SetFilteredRetrofitDataAction {
    type: 'SET_FILTERED_RETROFIT_DATA';
    filteredData: FilteredData;
}

interface SetRetrofitFilterAction {
    type: 'SET_RETROFIT_FILTER';
    filter: RetrofitFilter;
}

interface SetRetrofitFilterValues {
    type: 'SET_RETROFIT_FILTER_VALUES';
    retrofitFilter: RetrofitFilterValues;
}

interface SetSearchName {
    type: 'SET_SEARCH_NAME';
    name: string;
}

interface AddFavoriteProductAction {
    type: 'ADD_FAVORITE_PRODUCT';
    product: Product;
}

interface RemoveFavoriteProductAction {
    type: 'REMOVE_FAVORITE_PRODUCT';
    name: string;
}

interface ClearFavoriteProductAction {
    type: 'CLEAR_FAVORITE_PRODUCTS';
}

interface AddFavoriteFunctionsAction {
    type: 'ADD_FAVORITE_FUNCTIONS';
    functions: FunctionModel[];
}

interface RemoveFavoriteFunctionAction {
    type: 'REMOVE_FAVORITE_FUNCTION';
    name: string;
}

interface ClearFavoriteFunctionsAction {
    type: 'CLEAR_FAVORITE_FUNCTIONS';
}

interface SetMoreAttributesAction {
    type: 'SET_MORE_ATTRIBUTES';
}

interface SetMoreFunctionsAction {
    type: 'SET_MORE_FUNCTIONS';
}

interface ClearFilteredProductDataAction {
    type: 'CLEAR_FILTERED_PRODUCT_DATA';
}

interface ClearFilteredRetrofitDataAction {
    type: 'CLEAR_FILTERED_RETROFIT_DATA';
}

type KnownAction = ToggleLoadingAction | AddHiddenIdAction | AddNewIdsAction | ClearHiddenIdsAction | ClearNewIdsAction | RemoveNewIdAction | SetProductFilterAction | SetRetrofitFilterAction | SetFilteredProductDataAction | SetFilteredRetrofitDataAction | SetInitialDataAction | SetRetrofitFilterValues | SetMoreAttributesAction | SetMoreFunctionsAction | SetSearchName | AddFavoriteProductAction | RemoveFavoriteProductAction | ClearFavoriteProductAction | AddFavoriteFunctionsAction | RemoveFavoriteFunctionAction | ClearFavoriteFunctionsAction | ClearFilteredProductDataAction | ClearFilteredRetrofitDataAction;

export const actionCreators = {
    toggleLoading: (): ToggleLoadingAction => ({ type: 'TOGGLE_LOADING' }),
    addHiddenId: (name: string): AddHiddenIdAction => ({ type: 'ADD_HIDDEN_ID', name }),
    addNewIds: (names: string[]): AddNewIdsAction => ({ type: 'ADD_NEW_IDS', names }),
    clearHiddenIds: (): ClearHiddenIdsAction => ({ type: 'CLEAR_HIDDEN_IDS' }),
    clearNewIds: (): ClearNewIdsAction => ({ type: 'CLEAR_NEW_IDS' }),
    removeNewId: (name: string): RemoveNewIdAction => ({ type: 'REMOVE_NEW_ID', name }),
    setProductFilter: (filter: ProductFilter): SetProductFilterAction => ({ type: 'SET_PRODUCT_FILTER', filter }),
    setFilteredProductData: (filteredData: FilteredData): SetFilteredProductDataAction => ({ type: 'SET_FILTERED_PRODUCT_DATA', filteredData }),
    setRetrofitFilter: (filter: RetrofitFilter): SetRetrofitFilterAction => ({ type: 'SET_RETROFIT_FILTER', filter }),
    setFilteredRetrofitData: (filteredData: FilteredData): SetFilteredRetrofitDataAction => ({ type: 'SET_FILTERED_RETROFIT_DATA', filteredData }),
    setRetrofitFilterValues: (retrofitFilter: RetrofitFilterValues): SetRetrofitFilterValues => ({ type: 'SET_RETROFIT_FILTER_VALUES', retrofitFilter }),
    setInitialData: (initialData: InitialData): SetInitialDataAction => ({ type: 'SET_INITIAL_DATA', initialData }),
    setSearchName: (name: string): SetSearchName => ({ type: 'SET_SEARCH_NAME', name }),
    addFavoriteProduct: (product: Product): AddFavoriteProductAction => ({ type: 'ADD_FAVORITE_PRODUCT', product }),
    removeFavoriteProduct: (name: string): RemoveFavoriteProductAction => ({ type: 'REMOVE_FAVORITE_PRODUCT', name }),
    clearFavoriteProducts: (): ClearFavoriteProductAction => ({ type: 'CLEAR_FAVORITE_PRODUCTS' }),
    addFavoriteFunctions: (functions: FunctionModel[]): AddFavoriteFunctionsAction => ({ type: 'ADD_FAVORITE_FUNCTIONS', functions }),
    removeFavoriteFunction: (name: string): RemoveFavoriteFunctionAction => ({ type: 'REMOVE_FAVORITE_FUNCTION', name }),
    clearFavoriteFunctions: (): ClearFavoriteFunctionsAction => ({ type: 'CLEAR_FAVORITE_FUNCTIONS' }),
    toggleMoreAttributes: () => ({ type: 'SET_MORE_ATTRIBUTES' }),
    toggleMoreFunctions: () => ({ type: 'SET_MORE_FUNCTIONS' }),
    clearFilteredProductData: (): ClearFilteredProductDataAction => ({ type: 'CLEAR_FILTERED_PRODUCT_DATA' }),
    clearFilteredRetrofitData: (): ClearFilteredRetrofitDataAction => ({ type: 'CLEAR_FILTERED_RETROFIT_DATA' })
};

export const reducer: Reducer<State, KnownAction> = (state, action) => {
    if (state === undefined) {
        return {
            loading: false,
            moreAttributesOpen: false,
            moreFunctionsOpen: false,
            initialData: {
                applicationIds: []
            },
            products: [],
            hiddenIds: [],
            favoriteProducts: [],
            newIds: [],
            productFilter: {
                selectedApplicationId: undefined,
                selectedFunctionIds: []
            },
            favoriteFunctions: [],
            retroFilter: {
                selectedManufacturer: undefined,
                selectedProductShortCode: undefined
            },
            searchName: '',
            filteredProductData: {
                products: [],
                functions: []
            },
            filteredRetrofitData: {
                products: [],
                functions: []
            },
            retrofitFilterValues: {
                manufacturersAndProductShortCodes: []
            }
        };
    }

    switch (action.type) {
        case 'TOGGLE_LOADING':
            return { ...state, loading: !state.loading };
        case 'ADD_HIDDEN_ID':
            return { ...state, hiddenIds: [...state.hiddenIds, action.name] };
        case 'ADD_NEW_IDS':
            return { ...state, newIds: [...state.newIds, ...action.names] };
        case 'CLEAR_HIDDEN_IDS':
            return { ...state, hiddenIds: [] };
        case 'CLEAR_NEW_IDS':
            return { ...state, newIds: [] };
        case 'REMOVE_NEW_ID':
            return { ...state, newIds: state.newIds.filter(x => x !== action.name) };
        case 'SET_FILTERED_PRODUCT_DATA':
            return { ...state, filteredProductData: action.filteredData };
        case 'SET_FILTERED_RETROFIT_DATA':
            return { ...state, filteredRetrofitData: action.filteredData };
        case 'SET_PRODUCT_FILTER':
            return { ...state, productFilter: { ...state.productFilter, ...action.filter } };
        case 'SET_RETROFIT_FILTER':
            return { ...state, retroFilter: { ...state.retroFilter, ...action.filter } };
        case 'SET_INITIAL_DATA':
            return { ...state, initialData: action.initialData };
        case 'SET_RETROFIT_FILTER_VALUES':
            return { ...state, retrofitFilterValues: action.retrofitFilter };
        case 'SET_MORE_ATTRIBUTES':
            return { ...state, moreAttributesOpen: !state.moreAttributesOpen };
        case 'SET_MORE_FUNCTIONS':
            return { ...state, moreFunctionsOpen: !state.moreFunctionsOpen };
        case 'SET_SEARCH_NAME':
            return { ...state, searchName: action.name };
        case 'ADD_FAVORITE_PRODUCT':
            return { ...state, favoriteProducts: [action.product, ...state.favoriteProducts] };
        case 'REMOVE_FAVORITE_PRODUCT':
            return { ...state, favoriteProducts: state.favoriteProducts.filter(x => x.productFullName !== action.name) };
        case 'CLEAR_FAVORITE_PRODUCTS':
            return { ...state, favoriteProducts: [] };
        case 'ADD_FAVORITE_FUNCTIONS':
            return { ...state, favoriteFunctions: action.functions };
        case 'REMOVE_FAVORITE_FUNCTION':
            return { ...state, favoriteFunctions: state?.favoriteFunctions?.filter(x => x.productFunctionInfoPairs.find(y => `${Object.values(y)[0]}` !== action.name)) };
        case 'CLEAR_FAVORITE_FUNCTIONS':
            return { ...state, favoriteFunctions: [] };
        case 'CLEAR_FILTERED_PRODUCT_DATA':
            return { ...state, filteredProductData: { functions: [], products: [] } };
        case 'CLEAR_FILTERED_RETROFIT_DATA':
            return { ...state, filteredRetrofitData: { functions: [], products: [] } };
        default:
            return state;

    }
};
