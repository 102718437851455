import React, { useState } from 'react';
import { useTranslate } from '../../hooks/common';
import { useDispatch, useSelector } from 'react-redux';
import { TRANSLATIONS } from '../../constants';
import { CAROUSEL_RESPONSIVE } from '../../constants/CarouselResponsive';
import ContentContainer from '../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Jumbotron from '../common/Jumbotron';
import KeyVisual from '../common/KeyVisual';
import Section, { SectionColor } from '../common/Section';
import Select from '../common/Select';
import Modal from '../common/Modal';
import Carousel from '../common/Carousel';
import SelectorTagBar from './SelectorTagBar';
import Loader from '../common/Loader';
import keyVisual from '../../../assets/images/background-retrofit.jpg';
import { actionCreators, ApplicationState } from '../../store';
import RetrofitCard from './RetrofitCard';
import { getDistinctProductAttributeRows, setManufacturerFilter, setProductFilter, track } from '../../services';
import Button, { ButtonType } from '../common/Button';
import { useHook } from '../../hooks';

const RetrofitSelector = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const loading = useSelector((state: ApplicationState) => state.selector.loading);
    const filterValues = useSelector((state: ApplicationState) => state.selector.retrofitFilterValues);
    const retroFilter = useSelector((state: ApplicationState) => state.selector.retroFilter);
    const products = useSelector((state: ApplicationState) => state.selector.filteredRetrofitData.products);
    const [modalOpen, setModalOpen] = useState(false);
    const { getFilteredData } = useHook(x => x.retrofitFilter)();

    useHook(x => x.retrofitFilter)();

    const onManufacturerSelect = (manufacturer: string) => {
        const newFilter = setManufacturerFilter(manufacturer);

        dispatch(actionCreators.setRetrofitFilter(newFilter));
        getFilteredData(newFilter);
        setModalOpen(true);
    };

    const onProductSelect = (code: string) => {
        const newFilter = setProductFilter(retroFilter, code);

        track('RetrofitSelection', { manufacturer: retroFilter.selectedManufacturer, product: code });
        dispatch(actionCreators.setRetrofitFilter(newFilter));
        getFilteredData(newFilter);
    };

    return (
        <div className='selector'>
            <KeyVisual height={360} url={keyVisual}>
                <Flex direction={FlexDirection.Column} justification={FlexJustification.Center}>
                    <ContentContainer>
                        <Jumbotron className='retrofit-container'>
                            <Loader loading={loading}>
                                <div className='title'>{translate(TRANSLATIONS.retrofitSelection.filterTitle)}</div>
                                <Flex direction={FlexDirection.Column} justification={FlexJustification.Center} gap={25}>
                                    <div className='dropdown-filter'>
                                        <div className='subtitle'>{translate(TRANSLATIONS.retrofitSelection.manufacturer)}</div>
                                        <Select
                                            label={translate(TRANSLATIONS.retrofitSelection.pleaseSelect)}
                                            value={retroFilter.selectedManufacturer}
                                            values={filterValues.manufacturersAndProductShortCodes.map(x => x.key?.toString())}
                                            mapToString={x => x?.toString() ?? ''}
                                            onSelect={x => onManufacturerSelect(x ?? '')}
                                        />
                                    </div>
                                    <div className='dropdown-filter'>
                                        <div className='subtitle'>{translate(TRANSLATIONS.retrofitSelection.productShortCode)}</div>
                                        <Select
                                            label={translate(TRANSLATIONS.retrofitSelection.search)}
                                            value={retroFilter.selectedProductShortCode}
                                            values={filterValues.manufacturersAndProductShortCodes && 
                                                filterValues.manufacturersAndProductShortCodes.filter(x => x.key?.toString() === retroFilter.selectedManufacturer)[0]?.value}
                                            mapToString={x => x?.toString() ?? ''}
                                            onSelect={x => x && onProductSelect(x)}
                                            disabled={!retroFilter.selectedManufacturer}
                                        />
                                    </div>
                                </Flex>
                            </Loader>
                        </Jumbotron>
                    </ContentContainer>
                </Flex>
            </KeyVisual>
            <Section color={SectionColor.LightSand} style={{ paddingTop: 15 }}>
                <ContentContainer>
                    <SelectorTagBar backgroundColor={SectionColor.LightSand} />
                    <div className='results'>
                        <Loader loading={loading}>
                            {products.length ?
                                <Carousel slidesToShow={4} responsive={CAROUSEL_RESPONSIVE}>
                                    {products.map((x, i) =>
                                        <div key={i}>
                                            <Flex direction={FlexDirection.Row} justification={FlexJustification.Center}>
                                                <RetrofitCard
                                                    product={x}
                                                    isLegacy={i === 0}
                                                    isBestFit={i === 1}
                                                    distinctRows={getDistinctProductAttributeRows(products[i].productAttributes, products)}
                                                />
                                            </Flex>
                                        </div>
                                    )}
                                </Carousel> :
                                <div className='results-placeholder'>
                                    {!loading && translate(TRANSLATIONS.productResults.noProducts)}
                                </div>
                            }
                        </Loader>
                    </div>
                    <Modal open={modalOpen}>
                        <div className='title'>{translate((TRANSLATIONS.retrofitSelection as any)['dialog.info.retrofitDisclaimer.title'])}</div>
                        <div className='modal-text'>{translate((TRANSLATIONS.retrofitSelection as any)['dialog.info.retrofitDisclaimer.text'])}</div>
                        <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} style={{ width: '600px' }}>
                            <Button type={ButtonType.Tertiary} onClick={() => setModalOpen(false)}>
                                {translate((TRANSLATIONS.retrofitSelection as any)['dialog.info.retrofitDisclaimer.button'])}
                            </Button>
                        </Flex>
                    </Modal>
                </ContentContainer>
            </Section>
        </div>
    );
};

export default RetrofitSelector;
