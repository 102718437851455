import React from 'react';
import { useTranslate } from '../../hooks/common';
import { TRANSLATIONS } from '../../constants';
import ContentContainer from '../common/ContentContainer';
import Icon, { IconType } from '../common/Icon';

const Footer = () => {
    const translate = useTranslate();

    const renderWebsite = () => (
        <div className='footer-links footer-global-website'>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.siemensLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.openSiemensWebsite)}
            </a>
        </div>
    );

    const renderSocial = () => (
        <div className='footer-social-links'>
            <a className='social-link' href='https://www.linkedin.com/company/siemens' title='LinkedIn' target='_blank' rel='noopener noreferrer'>
                <Icon type={IconType.Linkedin} />
            </a>
            <a className='social-link' href='https://twitter.com/siemens' title='Twitter' target='_blank' rel='noopener noreferrer'>
                <Icon type={IconType.Twitter} />
            </a>
            <a className='social-link' href='https://www.facebook.com/Siemens' title='Facebook' target='_blank' rel='noopener noreferrer'>
                <Icon type={IconType.Facebook} />
            </a>
            <a className='social-link' href='https://www.youtube.com/user/Siemens' title='Youtube' target='_blank' rel='noopener noreferrer'>
                <Icon type={IconType.YouTube} />
            </a>
            <a className='social-link' href='https://www.instagram.com/siemens/' title='Instagram' target='_blank' rel='noopener noreferrer'>
                <Icon type={IconType.Instagram} />
            </a>
        </div>
    );

    const renderSiemens = () => (
        <div className='footer-links footer-siemens'>
            <a className='footer-link no-link'>
                {translate(TRANSLATIONS.footer.siemens).replace('{date}', new Date().getFullYear().toString())}
            </a>
        </div>
    );

    const renderContact = () => (
        <div className='footer-contact'>
            <a className='footer-contact-button' href={translate(TRANSLATIONS.footer.contactUsLink)} target='_blank' rel='noopener noreferrer'>
                {translate(TRANSLATIONS.footer.contactUs)}
                <Icon type={IconType.Email} />
            </a>
        </div>
    );

    const renderLegal = () => (
        <div className='footer-links footer-legal'>
            <a className='footer-link footer-global-website-link' target='_blank' href={translate(TRANSLATIONS.footer.siemensLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.openSiemensWebsite)}
            </a>
            <a className='footer-link no-link footer-siemens-link'>
                {translate(TRANSLATIONS.footer.siemens).replace('{date}', new Date().getFullYear().toString())}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.corporateInformationLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.corporateInformation)}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.privacyPolicyLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.privacyPolicy)}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.cookiePolicyLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.cookiePolicy)}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.termsOfUseLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.termsOfUse)}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.digitalIdLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.digitalId)}
            </a>
        </div>
    );

    return (
        <footer>
            <ContentContainer>
                <div className='footer-container'>
                    {renderWebsite()}
                    {renderSocial()}
                    {renderSiemens()}
                    {renderContact()}
                    {renderLegal()}
                </div>
            </ContentContainer>
        </footer>
    );
};

export default Footer;
