import React, { createRef, ReactText, RefObject, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { useTranslate } from '../../hooks/common';
import { TRANSLATIONS, DOUBLE_HEIGHT_KEYS } from '../../constants';
import { Product } from '../../models';
import Icon, { IconType } from '../common/Icon';
import Button, { ButtonType } from '../common/Button';
import Tooltip from '../common/Tooltip';
import { track } from '../../services';
import Tag, { TagColor } from '../common/Tag';
import { useHook } from '../../hooks';

interface CardProps {
    product: Product;
    isLegacy: boolean;
    isBestFit: boolean;
    distinctRows: boolean[];
}

const RetrofitCard = ({ product, isLegacy, isBestFit, distinctRows }: CardProps) => {
    const translate = useTranslate();
    const { getCardImagePath } = useHook(x => x.images)();
    const retroFilter = useSelector((state: ApplicationState) => state.selector.retroFilter);
    const [productLabelRef, setProductLabelRef] = useState<RefObject<HTMLDivElement>[]>([]);

    useEffect(() => {
        setProductLabelRef(refs => new Array(product.productAttributes.length).fill({}).map((x, i) => refs[i] || createRef<HTMLDivElement>()));
    }, []);

    const renderNameAndActions = () => {
        const marketingLink = translate((TRANSLATIONS.marketingSiteLinks as any)[product.productFullName]);

        return (
            <div className='name-and-actions'>
                <div className='mlfb' onClick={() => marketingLink && window.open(`https://${marketingLink}`, '_blank')}>
                    {product.marketingName}
                </div>
            </div>
        );
    };

    const renderProperty = (index: number, label: ReactText, value: ReactText | JSX.Element, highlight: boolean, doubleHeight: boolean, hidden: boolean) => (
        <div className='property-container'>
            <div className={`property ${doubleHeight ? 'double-height' : 'property-height'}`}>
                <Tooltip
                    hidden={hidden}
                    key={index}
                    id={`${product.productFullName}${index}`}
                    text={label.toString()}
                    delayHide={0}
                >
                    <div className='property-label' ref={productLabelRef[index]}>
                        {label}
                    </div>
                </Tooltip>
                <div className={`property-value ${highlight ? 'highlight' : ''}`}>
                    {value}
                </div>
            </div>
        </div>
    );

    const getPrice = (price: number) => price === -1 ? '-' : `${getFormattedPrice(price)} \u20AC`;

    const getFormattedPrice = (price: number) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    const dataSheetLink = translate((TRANSLATIONS.dataSheetLinks as any)[product.productFullName.toString()]);

    const configureLink = translate((TRANSLATIONS.configureLinks as any)[product.productFullName.toString()]);

    const handleDataSheetClick = () => {
        window.open(dataSheetLink, '_blank');
        track('DataSheetClick', { configureLink: dataSheetLink });
    };

    const handleConfigureClick = () => {
        window.open(configureLink, '_blank');
        track('ConfigureClick', { configureLink: configureLink });
    };

    return (
        <div className='card-container'>
            <div className='headline'>
                {isBestFit || isLegacy ? translate((TRANSLATIONS.retrofitSelection as any)[`${isLegacy ? 'yourSelection' : 'recommendation'}`]) : ''}
            </div>
            <div className='card'>
                <div className='thumbnail'>
                    <img src={getCardImagePath(product.productFullName, isLegacy)} />
                </div>
                <div className='header'>
                    {renderNameAndActions()}
                </div>
                <div className='details'>
                    {product.productAttributes && product.productAttributes.map((pair, i) => {
                        const attributeName = translate((TRANSLATIONS.functions as any)[`${Object.values(pair)[0]}`]);
                        const attributeValue = Object.values(pair)[1].value === null || Object.values(pair)[1].value === '-' ? '-/-' : Object.values(pair)[1].value;
                        const currentLabelRef = productLabelRef[i]?.current ?? {} as HTMLDivElement;
                        const showTooltip = currentLabelRef ? currentLabelRef?.scrollWidth > currentLabelRef?.offsetWidth : false;

                        return (renderProperty(i, attributeName, `${attributeValue}`, distinctRows[i], DOUBLE_HEIGHT_KEYS.includes(Number(Object.values(pair)[0])), !showTooltip));
                    })}
                    {product.comment && renderProperty(product.productAttributes.length + 1, '', product.comment, false, true, false)}
                </div>
                <div className='actions'>
                    <div className='best-price-container'>
                        {!isLegacy && product.isBestPrice &&
                            <div className='single-tag'>
                                <Tag backgroundColor={TagColor.Blue} text={translate(TRANSLATIONS.productResults.bestPrice)} />
                            </div>
                        }
                    </div>
                    <div className='price-item'>
                        {!isLegacy && 
                            <>
                                <div className='price'>{product.price ? getPrice(product.price) : translate(TRANSLATIONS.productResults.priceOnRequest)}</div>
                                <div className='list-price'>{translate(TRANSLATIONS.productResults.listPrice)}</div>
                            </>
                        }
                    </div>
                    {isLegacy && retroFilter.selectedManufacturer === 'SIEMENS' && <Button type={ButtonType.Secondary} onClick={() => window.open('https://www.downloads.siemens.com/download-center', '_blank')}>
                        <Icon type={IconType.Document} />
                        {translate(TRANSLATIONS.retrofitSelection.legacyDataSheet)}
                    </Button>}
                    {!isLegacy && <Button type={ButtonType.Secondary} onClick={handleDataSheetClick} disabled={dataSheetLink === undefined || dataSheetLink.startsWith('Missing')}>
                        <Icon type={IconType.Document} />
                        {translate(TRANSLATIONS.productResults.dataSheet)}
                    </Button>}
                    {!isLegacy ? <Button type={ButtonType.Primary} onClick={handleConfigureClick} disabled={configureLink === undefined || configureLink.startsWith('Missing')} >
                        <Icon type={IconType.Configuration} />
                        {translate(TRANSLATIONS.productResults.configureBuy)}
                    </Button> :
                    <div className='configure-and-buy'></div>}
                </div>
            </div>
        </div>
    );
};

export default RetrofitCard;
