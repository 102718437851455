import React from 'react';
import TagBar from '../common/TagBar';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, ApplicationState } from '../../store';
import { TagProps } from '../common/Tag';
import { SectionColor } from '../common/Section';
import { TRANSLATIONS } from '../../constants/Translations';
import { useTranslate } from '../../hooks/common';
import { HeaderTabType } from '../../enums';
import { useHook } from '../../hooks';

interface SelectorTagBarProps {
    backgroundColor: SectionColor;
}

const SelectorTagBar = ({ backgroundColor }: SelectorTagBarProps) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const productFilter = useSelector((state: ApplicationState) => state.selector.productFilter);
    const retroFilter = useSelector((state: ApplicationState) => state.selector.retroFilter);
    const hiddenIds = useSelector((state: ApplicationState) => state.selector.hiddenIds);
    const activeTabType = useSelector((state: ApplicationState) => state.layout.activeTabType);
    const { getFilteredData } = useHook(x => x.productFilter)();

    const getTagProps = (text: string, onClick: () => void): TagProps => ({ text, onClick, close: true });

    const handleHiddenIdReset = () => {
        dispatch(actionCreators.addNewIds(hiddenIds));
        dispatch(actionCreators.clearHiddenIds());
    };

    const handleApplicationReset = () => {
        dispatch(actionCreators.setProductFilter({ selectedApplicationId: undefined, selectedFunctionIds: [] }));
        dispatch(actionCreators.setSearchName(''));
        dispatch(actionCreators.clearFilteredProductData());
        dispatch(actionCreators.clearFavoriteFunctions());
        dispatch(actionCreators.clearFavoriteProducts());
        dispatch(actionCreators.clearHiddenIds());
        dispatch(actionCreators.clearNewIds());
    };

    const handleFunctionReset = () => {
        dispatch(actionCreators.setProductFilter({ selectedFunctionIds: [] }));
        getFilteredData({ selectedApplicationId: productFilter.selectedApplicationId, selectedFunctionIds: [] });
    };

    const handleManifacturingReset = () => {
        dispatch(actionCreators.setRetrofitFilter({ selectedManufacturer: undefined, selectedProductShortCode: undefined }));
        dispatch(actionCreators.clearFilteredRetrofitData());
    };

    const handleProductCodeReset = () => {
        dispatch(actionCreators.setRetrofitFilter({ selectedProductShortCode: undefined }));
        dispatch(actionCreators.clearFilteredRetrofitData());
    };

    const productTags: TagProps[] = [
        ...hiddenIds.length
            ? [getTagProps(translate(TRANSLATIONS.productSelection.filterActive), handleHiddenIdReset)]
            : [],
        ...productFilter.selectedApplicationId
            ? [getTagProps(translate((TRANSLATIONS.applications as any)[`${productFilter.selectedApplicationId}`]), handleApplicationReset)]
            : [],
        ...productFilter?.selectedFunctionIds?.map(x =>
            getTagProps(translate((TRANSLATIONS.functions as any)[x]), handleFunctionReset )
        ) ?? []
    ];

    const retrofitTags: TagProps[] = [
        ...retroFilter.selectedManufacturer
            ? [getTagProps(retroFilter.selectedManufacturer, handleManifacturingReset)]
            : [],
        ...retroFilter.selectedProductShortCode
            ? [getTagProps(retroFilter.selectedProductShortCode, handleProductCodeReset)]
            : []
    ];
    
    return (
        <div className='selector-tag-bar'>
            <TagBar tags={activeTabType === HeaderTabType.Products ? productTags : retrofitTags} backgroundColor={backgroundColor} />
        </div>
    );
};

export default SelectorTagBar;
